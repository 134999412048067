<template>
  <div class="schedule content">
    <div class="crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/myOrders' }">我的订单</el-breadcrumb-item>
        <el-breadcrumb-item>订单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="lease" v-if="list.order_info">
      <div class="lease_order">
        <img
          v-if="list.order_info.type === 1"
          src="../../assets/img/Frame 534@2x.png"
          alt=""
        />
        <img
          v-if="list.order_info.type === 2"
          src="../../assets/img/Frame 534_1@2x.png"
          alt=""
        />
        <span v-text="list.order_info.type === 1 ? '租赁订单' : '购买订单'"></span>
        <p style="color: #3d7eff">使用时间：{{ list.order_info.use_starttime }}</p>
        <p style="marginleft: 141px">订单编号：{{ list.order_info.order_sn }}</p>
        <p>下单时间：{{ list.order_info.create_time }}</p>
        <p
          v-if="list.order_info.order_status === 0"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待支付
        </p>
        <p
          v-if="list.order_info.order_status === 1"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待审核
        </p>
        <p
          v-if="list.order_info.order_status === 2"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待发货
        </p>
        <p
          v-if="list.order_info.order_status === 3"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待签收
        </p>
        <p
          v-if="list.order_info.order_status === 4"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待付尾款
        </p>
        <p
          v-if="list.order_info.order_status === 5"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:尾款待审核
        </p>
        <p
          v-if="list.order_info.order_status === 6"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:待回收
        </p>
        <p
          v-if="list.order_info.order_status === 7"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:已完成
        </p>
        <p
          v-if="list.order_info.order_status === 8"
          style="color: #ff863d; marginleft: auto"
        >
          订单状态:已取消
        </p>
      </div>
      <div class="steplist">
        <el-steps :active="active" align-center finish-status="success">
          <el-step
            title="提交订单"
            :description="list.order_info.order_create_time"
          ></el-step>
          <el-step title="付预付款" :description="list.order_info.pay_time"></el-step>
          <el-step title="发货" :description="list.order_info.sendgoodstime"></el-step>
          <el-step title="签收" :description="list.order_info.sign_time"></el-step>
          <el-step title="付尾款" :description="list.order_info.pay_time_two"></el-step>
          <el-step
            title="商家回收"
            :description="list.order_info.complate_back_time"
          ></el-step>
          <el-step
            title="订单完成"
            :description="list.order_info.complete_time"
          ></el-step>
          <el-step title="评价" :description="list.order_info.evaluate_time"></el-step>
        </el-steps>
      </div>
      <div class="information" v-if="list.order_express">
        <div class="essential">
          <div class="infotitle">
            <h3>基本信息</h3>
          </div>
          <div class="infotext">
            <p><span>收货人</span>{{ list.order_express.user_name }}</p>
            <p><span>联系方式</span>{{ list.order_express.user_phone }}</p>
            <p><span>收货地址</span>{{ list.order_express.user_address }}</p>
            <p><span>使用时间</span>{{ list.order_info.use_starttime }}</p>
            <p><span>使用天数</span>{{ list.order_info.use_days }}天</p>
            <p><span>备注</span>{{ list.order_info.order_remark }}</p>
            <p><span>交易类型</span>{{ list.order_info.type === 1 ? "租赁" : "购买" }}</p>
          </div>
        </div>
        <div class="transport">
          <div class="infotitle">
            <h3>运输信息</h3>
          </div>
          <div class="infotext">
            <p>
              <span>商品运输</span
              >{{ list.order_info.deliver_type === 0 ? "商家运输" : "自提" }}
            </p>
            <p>
              <span>运输公司</span
              >{{
                list.order_express.delivery_company
                  ? list.order_express.delivery_company
                  : "暂无信息"
              }}
            </p>
            <p>
              <span>运输单号</span
              >{{
                list.order_express.delivery_no
                  ? list.order_express.delivery_no
                  : "暂无信息"
              }}
            </p>
            <p>
              <span>负责人</span
              >{{
                list.order_express.delivery_name
                  ? list.order_express.delivery_name
                  : "暂无信息"
              }}
            </p>
            <p>
              <span>联系电话</span
              >{{
                list.order_express.delivery_phone
                  ? list.order_express.delivery_phone
                  : "暂无信息"
              }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="infotitle"
        style="margin: 0 16px"
        v-if="list.order_info.imgs.length > 0"
      >
        <h3>服务现场</h3>
      </div>
      <div class="serNow">
        <el-image
          style="width: 70px; height: 70px"
          :src="item"
          v-for="item in list.order_info.imgs"
          :key="item"
          :preview-src-list="list.order_info.imgs"
        >
        </el-image>
      </div>
      <div class="infotitle" style="margin: 0 16px">
        <h3>商品信息</h3>
      </div>
      <ul>
        <li v-for="item in list.order_goods" :key="item.goods_id">
          <template v-if="list.order_goods">
            <div class="detailed">
              <div>
                <img
                  :src="item.goods_thumb"
                  alt=""
                  v-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) !==
                      'mp4'
                  "
                />
                <video
                  :src="item.goods_thumb"
                  id="videos"
                  v-else-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) ===
                      'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="angeforderte">
                <div class="state">
                  <div class="goods_name">
                    {{ item.goods_name }}
                  </div>
                  <div class="divflex" style="flex: 1 auto">
                    <p class="w_150 gury">{{ item.specs_name }}</p>
                    <p>￥{{ item.goods_price }}</p>
                    <p>x{{ item.goods_num }}</p>
                    <p>
                      ￥{{
                        Math.floor(Number(item.goods_price) * item.goods_num * 100) / 100
                      }}
                    </p>
                  </div>
                </div>
                <div class="state">
                  <div class="w_202">附加服务</div>
                  <div style="flex: 1 auto">
                    <div class="divflex" v-for="(s, i) in item.order_service" :key="i">
                      <p class="gury w_150">{{ s.service_name }}</p>
                      <p>￥{{ s.service_price }}</p>
                      <p>x1</p>
                      <p>￥{{ s.service_price }}</p>
                    </div>
                  </div>
                </div>
                <div class="state">
                  <div class="w_202">运费</div>
                  <p class="autoleft">￥{{ item.express_price }}</p>
                </div>
              </div>
            </div>
            <div class="payType">
              <p v-if="item.order_pay_type === 1">付款方式：一次性付全款</p>
              <p v-if="item.order_pay_type === 2">
                付款方式：预付款{{ item.percent }}%+尾款{{ 100 - item.percent }}%
              </p>
              <!-- <p class="autoleft" v-if="list.order_info">
              合计<span>￥{{ list.order_info.total_money }}</span>
            </p> -->
            </div>
          </template>
        </li>
      </ul>
      <div class="all_price">
        <p v-if="list.order_info">
          商品总金额(含服务费)<span
            >￥{{ Math.floor((list.order_info.total_money - total) * 100) / 100 }}</span
          >
        </p>
        <p>
          运费金额<span>￥{{ total }}</span>
        </p>
        <p>
          合计总金额<span v-if="list.order_info"
            >￥{{ list.order_info.total_money }}</span
          >
        </p>
        <p>
          预付款金额<span v-if="list.order_info"
            >￥{{ list.order_info.frist_money }}</span
          >
        </p>
        <p>
          尾款金额<span v-if="list.order_info">￥{{ list.order_info.two_money }}</span>
        </p>
      </div>
      <div class="refund" v-if="list.order_refunds.length > 0">
        <div class="infotitle">
          <h3>退款信息</h3>
        </div>
        <ul v-for="(item, i) in list.order_refunds" :key="i">
          <li>
            <p>
              <span>退款状态</span>
              <span v-if="item.refund_status === 0">未退款</span>
              <span v-if="item.refund_status === 1">申请退款</span>
              <span v-if="item.refund_status === 2">退款驳回</span>
              <span v-if="item.refund_status === 3">已退款</span>
            </p>
          </li>
          <!-- 分隔符 -->
          <li></li>
          <li><span>申请退款时间</span>{{ item.refund_time }}</li>
          <li><span>退款审核时间</span>{{ item.refund_check_time }}</li>
          <li><span>申请退款原因</span>{{ item.refund_reson }}</li>
          <li v-if="item.check_result">
            <span>退款审核结果</span>{{ item.check_result === 1 ? "同意" : "拒绝" }}
          </li>
        </ul>
      </div>
    </div>
    <div class="settlement" v-if="active === 0">
      <p v-if="list.order_info">
        商品总金额(含服务费) ￥{{ list.order_info.total_money - total }}
      </p>
      <p v-if="list.order_info">尾款金额 ￥{{ list.order_info.two_money }}</p>
      <p>
        预付款金额
        <span v-if="list.order_info">￥{{ list.order_info.frist_money }}</span>
      </p>
      <el-button type="primary" @click="topay(list)">提交订单</el-button>
    </div>
    <div
      class="evaluate"
      v-if="
        list.evaluate_list &&
        !list.evaluate_list.create_time &&
        list.order_info.order_status > 6
      "
    >
      <div class="infotitle">
        <h3>评价信息</h3>
      </div>
      <div class="evbox">
        <div class="leftbox">
          <p class="li_title">商品评分</p>
          <ul>
            <li v-for="item in form.goods" :key="item.goods_id">
              <div class="li_left">
                <img
                  :src="item.goods_thumb"
                  alt=""
                  v-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) !==
                      'mp4'
                  "
                />
                <video
                  :src="item.goods_thumb"
                  id="videos"
                  v-else-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) ===
                      'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="li_right">
                <p>
                  {{ item.goods_name }}
                </p>
                <el-rate
                  v-model="item.goods_score"
                  show-score
                  text-color="#ff9900"
                  score-template="{value}"
                ></el-rate>
              </div>
            </li>
          </ul>
        </div>
        <div class="rightbox">
          <div class="rate">
            <p>综合评分</p>
            <el-rate
              v-model="form.score"
              show-score
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rate">
            <p>物流评分</p>
            <el-rate
              v-model="form.logistics_score"
              show-score
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rate">
            <p>服务评分</p>
            <el-rate
              v-model="form.service_score"
              show-score
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 8 }"
            placeholder="请填写评价内容"
            v-model="form.text"
          >
          </el-input>
          <el-button type="primary" plain @click="score">提交</el-button>
        </div>
      </div>
    </div>
    <div
      class="evaluate"
      v-if="
        list.evaluate_list &&
        list.evaluate_list.create_time &&
        list.order_info.order_status > 6
      "
    >
      <div class="infotitle">
        <h3>评价信息</h3>
      </div>
      <div class="evbox">
        <div class="leftbox">
          <p class="li_title">商品评分</p>
          <ul>
            <li v-for="item in form.goods" :key="item.goods_id">
              <div class="li_left">
                <img
                  :src="item.goods_thumb"
                  alt=""
                  v-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) !==
                      'mp4'
                  "
                />
                <video
                  :src="item.goods_thumb"
                  id="videos"
                  v-else-if="
                    item.goods_thumb !== '' &&
                    item.goods_thumb.substr(item.goods_thumb.lastIndexOf('.') + 1, 3) ===
                      'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="li_right">
                <p>
                  {{ item.goods_name }}
                </p>
                <el-rate
                  v-model="item.goods_score"
                  show-score
                  disabled
                  text-color="#ff9900"
                  score-template="{value}"
                ></el-rate>
              </div>
            </li>
          </ul>
        </div>
        <div class="rightbox">
          <div class="rate">
            <p>综合评分</p>
            <el-rate
              v-model="form.score"
              show-score
              disabled
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rate">
            <p>物流评分</p>
            <el-rate
              v-model="form.logistics_score"
              show-score
              disabled
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rate">
            <p>服务评分</p>
            <el-rate
              v-model="form.service_score"
              show-score
              disabled
              text-color="#ff9900"
              score-template="{value}"
            ></el-rate>
          </div>
          <div class="rateBox">
            <p>您的评价:</p>
            <p>{{ form.text }}</p>
            <p>{{ evaluateTime }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      value1: 5.0,
      form: {
        goods: [],
        score: 5,
        logistics_score: 5,
        service_score: 5,
        text: "",
      },
      textarea: "",
      list: {},
      evaluateTime: "",
      goods: [],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  methods: {
    getDetail() {
      const that = this;
      const data = {
        order_sn: that.$route.query.sn,
      };
      that.$http
        .postApi("/orderdetail", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.list = res.data.data;
            if (res.data.data.order_info.evaluate_time) {
              that.active = 8;
            } else {
              that.active = res.data.data.order_info.order_status;
            }
            Object.keys(that.form).forEach((ele) => {
              if (ele !== "text") {
                that.form[ele] = Number(res.data.data.evaluate_list[ele]);
              } else {
                that.form[ele] = res.data.data.evaluate_list[ele];
              }
            });
            that.form.goods = [];
            that.list.order_goods.forEach((ele) => {
              that.form.goods.push({
                goods_id: ele.goods_id,
                goods_thumb: ele.goods_thumb,
                goods_score: 5.0,
                goods_name: ele.goods_name,
              });
            });
            that.evaluateTime = res.data.data.evaluate_list.create_time;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    topay(goods) {
      let params = {
        total_price: goods.order_info.frist_money,
        orderNo: goods.order_info.order_sn,
        pay_type: goods.order_info.pay_type,
        order_status: goods.order_info.order_status,
        type: 2,
      };
      let g = {};
      if (goods.order_info.type === 1) {
        g.lease = JSON.parse(JSON.stringify(goods.order_goods));
        g.lease.forEach((ele, index) => {
          ele.infoid = index;
          ele.car_num = ele.goods_num;
        });
      } else if (goods.order_info.type === 2) {
        g.sell = JSON.parse(JSON.stringify(goods.order_goods));
        g.sell.forEach((ele, index) => {
          ele.infoid = index;
          ele.car_num = ele.goods_num;
        });
      }
      this.$store.commit("savegoods", g);
      sessionStorage.setItem("pay", JSON.stringify(params));
      this.$router.push("/payment");
    },
    score() {
      const that = this;
      const data = {
        member_id: localStorage.getItem("member_id"),
        order_id: that.list.order_info.order_sn,
        ...that.form,
      };
      that.$http
        .postApi("/set_evaluate", data)
        .then((res) => {
          if (res.data.code === 200) {
            // that.$message.success('评价成功！')
            that.$alert("评价成功！", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                that.getDetail();
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    total() {
      let sell = 0;
      if (this.list.order_goods) {
        this.list.order_goods.forEach((ele) => {
          sell += Math.floor(Number(ele.express_price) * 100);
        });
      }
      return sell / 100;
    },
  },
  // watch: {
  //   'form.goods'(val){
  //     console.log(val)
  //     val.forEach((ele, index) => {
  //       val[index] = 5
  //     })
  //   }
  // },
  mounted() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  padding-top: 24px;
  .crumb {
    margin-bottom: 26px;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .lease {
    border: 1px solid #e7eaed;
    background-color: #fff;
    padding-bottom: 12px;
    .lease_order {
      display: flex;
      align-items: center;
      padding: 0 24px;
      height: 54px;
      background: linear-gradient(
        180deg,
        rgba(61, 126, 255, 0.1),
        rgba(61, 126, 255, 0) 100%
      );
      img {
        width: 18px;
        height: 18px;
      }
      span {
        font-size: 18px;
        margin-left: 8px;
        color: #101b28;
      }
      p {
        margin-left: 16px;
        font-size: 14px;
      }
    }
    .steplist {
      margin: 52px 0 40px;
      /deep/.el-step__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }
      /deep/.el-step__title.is-success {
        color: #3d7eff;
      }
      /deep/.el-step__description.is-success {
        font-size: 12px;
        color: #97a5b7;
        line-height: 22px;
      }
      /deep/.el-step__head.is-success {
        color: #3d7eff;
        border-color: #3d7eff;
      }
      /deep/.el-step__main {
        margin-top: 14px;
      }
      /deep/.el-step__description {
        margin-top: 1px;
      }
    }
    .information {
      display: flex;
      justify-content: space-between;
      padding: 0 16px 16px;
      .essential,
      .transport {
        width: 49%;
        height: 352px;
        border: 1px solid #f5f5f6;
        .infotext {
          padding: 16px;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #101b28;
            line-height: 24px;
          }
          span {
            display: inline-block;
            color: #97a5b7;
            width: 91px;
            margin-right: 12px;
          }
          p + p {
            margin-top: 16px;
          }
        }
      }
    }
    .refund {
      width: 100%;
      ul {
        width: 70%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 50%;
          border-bottom: none;
          font-size: 14px;
          font-weight: 500;
          color: #101b28;
          line-height: 24px;
          span {
            display: inline-block;
            color: #97a5b7;
            width: 91px;
            margin-right: 12px;
          }
          p + p {
            margin-top: 16px;
          }
        }
      }
    }
    ul {
      padding: 0 40px;
      li {
        margin-top: 16px;
        border-bottom: 1px solid #f5f5f6;
        .detailed {
          display: flex;
          video,
          img {
            width: 88px;
            height: 88px;
            margin-right: 16px;
          }
          .angeforderte {
            width: 100%;
            .state {
              display: flex;
              // justify-content: space-between;
              margin-bottom: 17px;
              .w_202 {
                width: 202px;
              }
              .w_150 {
                width: 150px;
              }
              .divflex {
                display: flex;
                justify-content: space-between;
              }
              div {
                font-size: 14px;
                font-weight: 500;
                color: #101b28;
                line-height: 20px;
              }
              .goods_name {
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                width: 192px;
                padding-right: 10px;
              }
            }
            .gury {
              color: #97a5b7;
            }
          }
        }
        .payType {
          height: 56px;
          background: #f5f5f6;
          display: flex;
          align-items: center;
          padding-left: 26px;
          padding-right: 6px;
          margin-bottom: 16px;
          p {
            font-size: 14px;
            color: #101b28;
            span {
              color: #ff863d;
              font-weight: 700;
            }
          }
        }
        .autoleft {
          margin-left: auto;
        }
      }
    }
    .all_price {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 8px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #101b28;
        margin-right: 32px;
        span {
          color: #ff863d;
          margin-left: 16px;
        }
      }
    }
  }
  .settlement {
    height: 64px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin: 12px 0 6px;
    margin-top: 129px;
    bottom: 0;
    padding: 0 6px;
    .el-button {
      width: 142px;
      height: 52px;
      border-radius: 2px;
    }
    p {
      margin-right: 24px;
      span {
        font-weight: 700;
        font-size: 15px;
        color: #ff863d;
      }
    }
  }
  .infotitle {
    height: 56px;
    background: #f5f5f6;
    padding: 0 16px;
    line-height: 56px;
    h3 {
      font-size: 14px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .serNow {
    margin: 10px 16px 6px;
    /deep/.el-image {
      margin-right: 4px;
      margin-bottom: 10px;
    }
  }
  .evaluate {
    .evbox {
      display: flex;
      align-items: center;
      background-color: #fff;
      // padding: 32px 30px 16px;
      padding-bottom: 32px;
      .leftbox {
        border: 1px solid #f5f5f6;
        margin-right: 72px;
        padding: 32px 30px;
        .li_title {
          font-size: 14px;
          font-weight: 700;
          text-align: LEFT;
          color: #525b66;
          line-height: 24px;
          padding-bottom: 8px;
          border-bottom: 1px solid #f5f5f6;
        }
        ul {
          height: 452px;
          overflow-y: auto;
          li {
            display: flex;
            align-items: center;
            .li_left {
              width: 92px;
              height: 92px;
              border-radius: 4px;
              margin-right: 6px;
              video,
              img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
              }
            }
            .li_right {
              p {
                max-width: 225px;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                font-size: 12px;
                font-weight: 400;
                color: #101b28;
                margin-bottom: 16px;
              }
              .el-rate {
                height: 24px;
                /deep/.el-rate__icon {
                  font-size: 24px;
                }
              }
            }
          }
        }
        ul::-webkit-scrollbar {
          width: 8px;
        }
        ul::-webkit-scrollbar-track {
          background-color: #f5f5f6;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
        ul::-webkit-scrollbar-thumb {
          background-color: #e7eaed;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
      }
      .rightbox {
        .rate {
          display: flex;
          align-items: center;
          p {
            font-size: 14px;
            font-weight: 500;
            color: #97a5b7;
            margin-right: 16px;
          }
          .el-rate {
            height: 32px;
            /deep/.el-rate__icon {
              font-size: 32px;
            }
          }
        }
        .rateBox {
          margin-top: 48px;
          p {
            font-size: 15px;
            color: #97a5b7;
            text-align: left;
            margin-bottom: 12px;
            &:nth-child(2) {
              color: #101b28;
              max-width: 405px;
            }
          }
        }
        .rate + .rate {
          margin-top: 16px;
        }
        text-align: right;
        .el-textarea {
          max-width: 604px;
        }
        .el-button {
          margin-top: 24px;
          width: 144px;
          height: 40px;
          background: rgba(61, 126, 255, 0.2);
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
