<template>
  <div class="goodslist content">
    <div class="goodsContent">
      <div class="contentTop">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>商品分类</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="contentCenter">
        <div class="c-left">
          <div class="allType">
            <h3 @click="switchData(0)">全部商品</h3>
            <p></p>
            <span>精选商品海量货源</span>
          </div>
          <ul>
            <li v-for="item in typeList" :key="item.type_id">
              <h3 :class="{ act: active === Number(item.type_id) }">
                <span @click="switchData(item.type_id, 'first')">{{
                  item.type_name
                }}</span>
              </h3>
              <div>
                <span
                  v-for="s in item.second"
                  :key="s.type_id"
                  :class="{ act: active === s.type_id }"
                  @click.stop="switchData(s.type_id, 'second')"
                  >{{ s.type_name }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="c-right">
          <div class="screen">
            <ul @click.stop="checkOrder($event)">
              <li data-index="0" :class="{ active: currIndex === '0' }">
                综合排序
              </li>
              <li
                class="prices"
                data-index="score"
                :class="{ active: currIndex === 'score' }"
              >
                评价
                <div>
                  <img
                    src="../../assets/img/icon_caret-up.png"
                    id="ascs"
                    alt=""
                    @click.stop="sorts('asc')"
                  />
                  <img
                    src="../../assets/img/icon_caret-down.png"
                    id="descs"
                    alt=""
                    @click.stop="sorts('desc')"
                  />
                </div>
              </li>
              <li
                class="prices"
                data-index="min_lease"
                :class="{ active: currIndex === 'min_lease' }"
              >
                价格
                <div>
                  <img
                    src="../../assets/img/icon_caret-up.png"
                    id="asc"
                    alt=""
                    @click.stop="sort('asc')"
                  />
                  <img
                    src="../../assets/img/icon_caret-down.png"
                    id="desc"
                    alt=""
                    @click.stop="sort('desc')"
                  />
                </div>
              </li>
            </ul>
            <div class="inputBox">
              <el-input
                style="width: 80px"
                v-model="begin_price"
                @blur.stop="search()"
              >
                <span slot="prefix" style="line-height: 32px"
                  >￥</span
                > </el-input
              >-
              <el-input
                style="width: 80px"
                v-model="end_price"
                @blur.stop="search()"
              >
                <span slot="prefix" style="line-height: 32px">￥</span>
              </el-input>
              <p>配送至</p>
              <el-select
                v-model="form.mitval"
                placeholder="请选择省"
                @change="checkarea"
                value-key="code"
              >
                <el-option
                  v-for="item in mitArea"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.cityval"
                placeholder="请选择市"
                @change="checkarea"
                value-key="code"
              >
                <el-option
                  v-for="item in city"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="form.areaval"
                placeholder="请选择区"
                @change="checkarea"
                value-key="code"
              >
                <el-option
                  v-for="item in Areas"
                  :key="item.code"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-button plain @click="rest">重置</el-button>
            </div>
          </div>
          <ul class="screenCenter" v-if="tableData.length > 0">
            <li
              v-for="item in tableData"
              :key="item.goods_id"
              @click="todetail(item.goods_id)"
            >
              <div class="liTop">
                <img
                  :src="item.cover_photo"
                  alt=""
                  v-if="
                    item.cover_photo !== '' &&
                    item.cover_photo.substr(
                      item.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) !== 'mp4'
                  "
                />
                <video
                  :src="item.cover_photo"
                  id="videos"
                  v-else-if="
                    item.cover_photo !== '' &&
                    item.cover_photo.substr(
                      item.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) === 'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="liContent">
                <h3>{{ item.goods_name }}</h3>
                <div class="price">
                  <div>
                    <p class="org" v-show="item.is_lease === 1">
                      <img
                        src="../../assets/img/Food Icons _ Hot Icon@2x.png"
                        alt=""
                      />
                      租赁￥{{ item.lease_price }}起
                    </p>
                  </div>
                  <p v-if="item.is_sell === 1">
                    售价<span>￥{{ item.sell_price }}</span
                    >起
                  </p>
                </div>
                <div class="attach">
                  <span
                    v-for="(a, index) in item.service"
                    :key="index"
                    ><template v-if="index < 2">{{ a.service_name }}</template></span
                  >
                </div>
              </div>
            </li>
          </ul>
          <div class="empty" v-else>
            <img src="../../assets/img/Frame 785.png" alt="" />
            <div v-if="!$route.query.q">
              <h4>该分类下还没有商品</h4>
              <p>去看看其他分类下的商品吧～</p>
            </div>
            <div v-if="$route.query.q">
              <h4>没有找到相关商品</h4>
              <p>换个词试试吧～</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="handlePage" v-if="tableData.length > 0">
      <el-pagination
        hide-on-single-page
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="32"
        background
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: '',
      typeList: [],
      tableData: [],
      total: 0,
      page: 1,
      active: 0, // 分类选中
      level: '', // 判断一二级分类
      currIndex: '0',
      vals: {},
      end_price: '',
      begin_price: '',
      mitArea: [],
      Areas: [],
      city: [],
      form: {
        area_id: '',
        mitval: '',
        cityval: '',
        areaval: '',
      },
      order_sort: 'asc'
    }
  },
  methods: {
    checkarea (val) {
      const that = this
      this.form.area_id = val.code
      this.search()
      if (val.type !== 3) {
        this.getArea(val.code, val.type)
      }
      if (val.type) {

      }
    },
    rest () {
      this.form.cityval = ''
      this.form.areaval = ''
      this.form.mitval = ''
      this.form.area_id = ''
      this.currIndex = '0'
      this.begin_price = ''
      this.end_price = ''
      this.order_sort = 'asc'
      this.search()
    },
    getArea (code, type) {
      const that = this
      const data = {
        area_code: code || 0,
        type: type || 0
      }
      that.$http.postApi('/getbusarea', data).then(res => {
        if (res.data.code === 200) {
          if (type === 1) {
            that.form.cityval = ''
            that.form.areaval = ''
            that.city = res.data.data
          } else if (type === 2) {
            that.form.areaval = ''
            that.Areas = res.data.data
          } else {
            that.mitArea = res.data.data
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleCurrentChange (val) {
      this.page = val
      this.getdata()
    },
    todetail (id) {
      let routeData = this.$router.resolve({ path: '/detail', query: { item: id } });
      window.open(routeData.href, '_blank');
    },
    checkOrder (e) {
      if (e.target.tagName === 'LI') {
        this.currIndex = e.target.attributes['data-index'].value
        this.search()
      }
    },
    sorts (type) {
      this.currIndex = 'score'
      this.order_sort = type
      this.search()
      if (type === 'asc') {
        ascs.src = require('../../assets/img/icon_caret-up.png')
        descs.src = require('../../assets/img/icon_caret-down.png')
      } else if (type === 'desc') {
        ascs.src = require('../../assets/img/icon_caret-up1.png')
        descs.src = require('../../assets/img/icon_caret-down1.png')
      }
    },
    sort (type) {
      this.currIndex = 'min_lease'
      this.order_sort = type
      this.search()
      if (type === 'asc') {
        asc.src = require('../../assets/img/icon_caret-up.png')
        desc.src = require('../../assets/img/icon_caret-down.png')
      } else if (type === 'desc') {
        asc.src = require('../../assets/img/icon_caret-up1.png')
        desc.src = require('../../assets/img/icon_caret-down1.png')
      }
    },
    search () {
      let val = {}
      if (this.currIndex !== '0') {
        val.order = this.currIndex
      }
      if (this.begin_price !== '') {
        val.begin_price = Number(this.begin_price)
      }
      if (this.end_price !== '') {
        val.end_price = Number(this.end_price)
      }
      if (this.form.area_id !== '') {
        val.freight_area = this.form.area_id
      }
      if (this.order_sort !== '') {
        val.order_sort = this.order_sort
      }
      this.vals = val
      this.page = 1
      this.getdata()
    },
    switchData (id, level) {
      this.active = id
      this.level = level
      this.getdata()
    },
    getdata () {
      const that = this
      const data = {
        pagesize: '32',
        page: that.page
      }
      if (typeof that.$route.query.q !== 'undefined') {
        data.msg = decodeURI(that.$route.query.q)
      }
      if (that.level === 'second') {
        data.type_second_id = that.active
      } else if (that.level === 'first') {
        data.type_id = that.active
      }
      Object.assign(data, that.vals)
      that.$http.postApi('/goods_list', data).then(res => {
        if (res.data.code === 200) {
          console.log(res)
          that.tableData = res.data.data.list
          that.total = res.data.data.count
        }
      }).catch(err => {
        console.log(err)
      })
    },
    gettype () {
      const that = this
      that.$http.postApi('/pc_goods_type', {}).then(res => {
        if (res.data.code === 200) {
          that.typeList = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
  created() {
  },
  mounted () {
    this.gettype()
    this.getArea()
    // this.active = this.$bus.sendData
    if (this.$bus.sendData) {
      return this.switchData(this.$bus.sendData, 'first')
    }
    this.getdata()
  },
  // beforeDestroy () {
  //   this.$bus.$off('updateData');
  // },
  watch: {
    '$route': function (to, from) {
      this.getdata()
    }
  }
}
</script>

<style lang="scss" scoped>
.goodslist {
  .goodsContent {
    padding-top: 35px;
    .contentTop {
      margin-bottom: 20px;
      .el-breadcrumb__item {
        font-size: 16px;
        color: #101b28;
      }
    }
    .contentCenter {
      display: flex;
      .c-left {
        width: 247px;
        min-width: 247px;
        display: table;
        margin-right: 16px;
        padding: 16px;
        padding-right: 0;
        min-height: 100vh;
        background-color: #fff;
        -ms-overflow-style: none;
        scrollbar-width: none;
        .allType {
          display: flex;
          align-items: center;
          h3 {
            font-size: 20px;
            font-weight: 700;
            color: #101b28;
            cursor: pointer;
          }
          p {
            width: 1px;
            height: 16px;
            background: #e7eaed;
            margin: 0 13px;
          }
          span {
            font-size: 12px;
            color: #525b66;
          }
        }
        ul {
          li {
            margin-top: 32px;
            cursor: pointer;
            h3,
            span {
              line-height: 22px;
              font-size: 14px;
              margin-bottom: 9px;
              cursor: pointer;
            }
            h3 {
              font-weight: 700;
              color: #182235;
            }
            h3.act {
              color: #3d7eff;
            }
            div {
              display: flex;
              flex-wrap: wrap;
              span {
                font-weight: 500;
                color: #525b66;
                margin-right: 24px;
              }
              span.act {
                color: #3d7eff;
              }
            }
          }
        }
      }
      .c-left::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .c-right {
        width: 100%;
        .screen {
          // max-width: 916px;
          min-height: 56px;
          background-color: #fff;
          margin-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
          padding: 0 16px;
          margin-right: -5px;
          ul {
            display: flex;
            align-items: center;
            li {
              cursor: pointer;
              font-size: 14px;
              font-weight: 500;
              color: #101b28;
              line-height: 56px;
            }
            li.active {
              color: #3d7eff;
            }
            .prices {
              display: flex;
              margin-right: 20px;
              div {
                display: flex;
                flex-direction: column;
                // align-items: flex-start;
                justify-content: center;
              }
            }
            li + li {
              margin-left: 24px;
            }
          }
          .inputBox {
            display: flex;
            align-items: center;
            .el-select {
              width: 105px;
            }
            p {
              font-size: 14px;
              font-weight: 500;
              color: #101b28;
              line-height: 22px;
              margin-right: 12px;
              margin-left: 26px;
            }
            .el-select + .el-select {
              margin-left: 8px;
            }
            .el-button {
              margin-left: 19px;
              padding: 8px 20px;
            }
            /deep/.el-input__inner {
              height: 32px;
              line-height: 32px;
            }
            /deep/.el-input__icon {
              line-height: 32px;
            }
          }
        }
        .screenCenter {
          overflow: auto;
          display: flex;
          flex-wrap: wrap;
          // justify-content: space-between;
          // max-height: 1314px;
          margin-right: -16px;
          li {
            margin-right: 16px;
            width: 216px;
            height: 368px;
            background-color: #fff;
            margin-bottom: 10px;
            border: 1px solid #fff;
            .liTop {
              height: 216px;
              video,
              img {
                width: 100%;
                height: 100%;
              }
            }
            .liContent {
              padding: 16px;
              h3 {
                font-size: 16px;
                font-weight: 700;
                color: #101b28;
                height: 44px;
                margin-bottom: 8px;
                max-width: 185px;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .attach {
                span {
                  font-size: 12px;
                  color: #525b66;
                }
                span + span {
                  margin-left: 17px;
                }
              }
              .price {
                margin-bottom: 4px;
                height: 43px;
                div {
                  height: 19px;
                  margin-bottom: 5px;
                }
                img {
                  width: 17px;
                  height: 17px;
                  margin-right: 4px;
                }
                p {
                  font-size: 12px;
                  color: #3c5169;
                  display: flex;
                  align-items: center;
                  span {
                    font-size: 14px;
                    font-weight: 700;
                    color: #19293d;
                  }
                }
                p.org {
                  margin-left: 0;
                  font-size: 14px;
                  font-weight: 700;
                  color: #ff863d;
                }
              }
            }
            &:hover {
              border: 1px solid #3d7eff;
            }
          }
          &::after {
            content: '';
            width: 217px;
          }
        }
        .empty {
          margin-top: 110px;
          margin-bottom: 13.5vh;
          text-align: center;
          h4 {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 400;
            color: #525b66;
            line-height: 28px;
          }
          p {
            font-size: 14px;
            color: #97a5b7;
            line-height: 28px;
            margin-bottom: 24px;
          }
          .el-button {
            width: 144px;
            height: 40px;
            border-radius: 2px;
          }
        }
        ul::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
    }
  }
  .handlePage {
    display: flex;
    justify-content: center;
    padding: 93px 0 60px;
    /deep/.el-pagination.is-background .el-pager {
      li {
        background-color: #fff;
      }
      li:not(.disabled).active {
        color: #409eff;
      }
    }
  }
}
</style>