<template>
  <div class="customized">
    <div class="header">
      <div class="nav">
        <div class="navBox">
          <p>七星会展共享展具平台</p>
          <div class="navBar">
            <ul>
              <li @click="$router.push('/')">首页</li>
              <li @click="toPage('/goodslist')">商品中心</li>
              <li v-if="totalUnreadCount > 99" @click="toim">我的消息(99+)</li>
              <li v-else-if="totalUnreadCount !== 0" @click="toim">
                我的消息({{ totalUnreadCount }})
              </li>
              <li v-else @click="toim">我的消息</li>
              <li @click="toPage('/myFavorites')">我的收藏</li>
              <li @click="toPage('/myOrders')">我的订单</li>
              <li @click="toPage('/shoppingCartItem')">
                购物车
                <span v-if="$store.getters.getnum">{{ $store.getters.getnum }}</span>
              </li>
            </ul>
            <div class="info">
              <el-avatar
                :src="$store.getters.getavatar"
                v-if="$store.getters.getavatar && $store.getters.getavatar !== ''"
              ></el-avatar>
              <el-dropdown v-if="user !== '' && user">
                <span class="el-dropdown-link">
                  <span>{{ user }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="toPage('/myProfile')"
                    >会员中心</el-dropdown-item
                  >
                  <el-dropdown-item divided>退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span class="el-dropdown-link" @click="toPage('/login')" v-else>
                请登录
              </span>
            </div>
            <p class="tel">{{ $store.getters.gettel }}</p>
          </div>
        </div>
      </div>
      <div class="banner">
        <img src="../../assets/img/Frame 789.png" alt="" />
        <div class="content">
          <el-button type="primary" @click="toim">立即咨询</el-button>
        </div>
      </div>
    </div>
    <cus v-if="$route.path === '/customized'"></cus>
    <design v-if="$route.path === '/design'"></design>
    <div class="footer">
      <div class="content footbox">
        <div class="company">
          <p>
            <a @click="topage('/pc_agree')">用户协议</a><span></span><a @click="topage('/pc_policy')">隐私政策</a><span></span
            ><a @click="topage('/activity', bz_frame_id)">帮助中心</a>
          </p>
          <p>Copyright© 青岛七星国际会展科技有限公司 版权所有 备案号：<a href="https://beian.miit.gov.cn" target="_blank">鲁ICP备15022445号-2</a></p>
          <p style="font-size: 14px">
            注册地址：青岛市崂山区海口路33号麦岛家园5号楼2108室 电话：0532-68691313
          </p>
        </div>
        <div class="code">
          <p>扫码关注公众号</p>
          <img src="../../assets/img/weixin.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import cus from "./cus.vue";
import design from "./design.vue";
export default {
  data() {
    return {};
  },
  components: {
    cus,
    design,
  },
  computed: {
    ...mapGetters(["totalUnreadCount"]),
    user() {
      return localStorage.getItem("nickname");
    },
  },
  methods: {
    toim() {
      this.$emit("toim", "");
    },
    toPage(page) {
      this.$router.push(page);
    },
    topage(page, id, item) {
      let routeData = this.$router.resolve({ path: page, query: { id: id, item: item } });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.customized {
  .header {
    width: 100%;
    .nav,
    .navBar,
    .navBar ul,
    .info {
      display: flex;
      align-items: center;
    }
    .nav {
      height: 56px;
      background: #101b28;
      color: #ffffff;
      justify-content: center;
      // padding: 0 17.75%;
      .navBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1200px;
      }
      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
      }
      .navBar {
        margin-left: auto;
        ul {
          li {
            font-size: 14px;
            line-height: 18px;
            margin-right: 32px;
            cursor: pointer;
            span {
              display: inline-block;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #ff863d;
              font-size: 8px;
              text-align: center;
              line-height: 14px;
              vertical-align: middle;
            }
          }
        }
        .info {
          line-height: 18px;
          margin-right: 32px;
          .el-avatar {
            width: 32px;
            height: 32px;
            /deep/img {
              width: 100%;
              height: auto;
            }
          }
          .el-dropdown-link {
            cursor: pointer;
            color: white;
            margin-left: 8px;
            display: inline-block;
            span {
              display: inline-block;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .el-icon-arrow-down {
            font-size: 12px;
          }
        }
      }
      .tel {
        background-image: -webkit-linear-gradient(97deg, #3f7fff 0%, #7cc0ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 36px;
        font-family: "SedanSC";
      }
    }
  }
  .banner {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    div {
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      .el-button {
        margin-left: 15px;
        width: 144px;
        height: 40px;
        border-radius: 2px;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
      }
    }
  }
  .footer {
    width: 100%;
    background: #101b28;
    .footbox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 227px;
    }
    .company {
      flex: 1;
      text-align: center;
      p {
        font-weight: 700;
        color: #97a5b7;
        line-height: 18px;
        a {
          color: #97a5b7;
        }
        span {
          background-color: #97a5b7;
          width: 1px;
          height: 18px;
          display: inline-block;
          margin: 0 32px;
        }
      }
      p + p {
        margin-top: 32px;
      }
    }
    .code {
      text-align: right;
      p {
        font-weight: 700;
        text-align: LEFT;
        color: #ffffff;
        line-height: 18px;
        margin-bottom: 17px;
      }
      img {
        width: 100px;
        height: 100px;
      }
    }
    p {
      color: #97a5b7;
    }
  }
}
</style>
