<template>
  <div class="myProfile">
    <el-form ref="form" :model="form" label-width="100px" label-position="left">
      <el-form-item label="原密码" v-if="form.type === 1" prop="password">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="手机号" v-if="form.type === 2" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="验证码" v-if="form.type === 2" prop="msgcode">
        <el-input v-model="form.msgcode"></el-input>
        <el-button type="text" class="code" @click="sendcode">{{
          code
        }}</el-button>
      </el-form-item>
      <el-form-item label="新密码" prop="newpassword">
        <el-input v-model="form.newpassword"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="repeatpassword">
        <el-input v-model="form.repeatpassword"></el-input>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button type="text" v-if="form.type === 1" @click="form.type = 2"
          >忘记密码？</el-button
        >
        <el-button type="text" v-if="form.type === 2" @click="form.type = 1"
          >使用原密码修改</el-button
        >
        <el-button type="primary" @click="onSubmit('form')" class="submit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <div class="saves">保存成功</div>
  </div>
</template>

<script>
export default {
  data () {
    var validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      form: {
        password: '',
        newpassword: '',
        repeatpassword: '',
        phone: '',
        msgcode: '',
        type: 1,
      },
      rules: {
        phone: [
          { validator: validateUser, trigger: "blur" },
          { pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0-9]|18[0-9]|14[57])[0-9]{8}$/, message: '请输入正确的手机号', trigger: 'click' }
          // { pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/, message: '请输入正确的手机号', trigger: 'click' }
        ],
        newpassword: [{ validator: validatePass, trigger: "blur" }],
        msgcode: [{ validator: validateCode, trigger: "blur" }],
      },
      code: '发送验证码'
    }
  },
  methods: {
    onSubmit (formName) {
      const that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {}
          if (that.form.type === 1) {
            data = {
              member_id: localStorage.getItem('member_id'),
              type: 1,
              password: that.form.password,
              newpassword: that.form.newpassword,
              repeatpassword: that.form.repeatpassword
            }
          } else {
            data = {
              member_id: localStorage.getItem('member_id'),
              type: 2,
              phone: that.form.phone,
              newpassword: that.form.newpassword,
              repeatpassword: that.form.repeatpassword,
              msgcode: that.form.msgcode,
              myselfphone: localStorage.getItem('phone')
            }
          }
          that.$http.postApi('/edit_pass', data).then(res => {
            if (res.data.code === 200) {
              document.getElementsByClassName('saves')[0].style.opacity = 0.5
              setTimeout(() => {
                document.getElementsByClassName('saves')[0].style.opacity = 0
              }, 2000)
              window.localStorage.removeItem('member_id')
              window.localStorage.removeItem('token')
              window.localStorage.removeItem('sign')
              that.$router.push('/login')
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    sendcode () {
      const that = this
      let text = /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0-9]|18[0-9]|14[57])[0-9]{8}$/
      if (text.test(that.form.phone)) {
        if (that.code === '发送验证码' || that.code === '重新发送') {
          const data = {
            phone: that.form.phone
          }
          that.$http.postApi('/sendCode', data).then(res => {
            if (res.data.code === 200) {
              // that.$message.success('发送成功！')
              let time = 60
              let timer = setInterval(() => {
                if (time > 0) {
                  time--
                  that.code = '倒计时' + time + 's'
                } else {
                  that.code = '重新发送'
                  clearInterval(timer)
                }
              }, 600)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        that.$message.error('请输入正确的手机号！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myProfile {
  background-color: #fff;
  padding: 62px 62px 32px 62px;
  margin-bottom: 49px;
  position: relative;
  .el-form {
    width: 60%;
  }
  .myavator {
    width: 115px;
    height: 115px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      width: 100%;
      height: 36px;
      background: rgba($color: #000000, $alpha: 0.5);
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
      line-height: 36px;
      position: absolute;
      bottom: 0;
      cursor: pointer;
    }
  }
  .code {
    position: absolute;
    right: 16px;
  }
  .danger {
    display: flex;
    .el-button {
      margin-left: auto;
      width: 80px;
      height: 32px;
      // line-height: 32px;
      padding: 0;
      border-radius: 4px;
      border: none;
    }
  }
  .submit {
    width: 144px;
    height: 40px;
    border-radius: 2px;
  }
  .saves {
    width: 179px;
    height: 54px;
    // opacity: 0.5;
    background: #101b28;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    line-height: 54px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 110px;
    // display: none;
    opacity: 0;
    transition: opacity 2s ease;
  }
}
</style>