<template>
  <div class="detail">
    <div class="content">
      <div class="navigation">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/#/goodslist">商品分类</a></el-breadcrumb-item>
          <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="domain">
        <div class="main-nav">
          <div class="larger">
            <el-carousel
              indicator-position="none"
              ref="carous"
              id="carous"
              :autoplay="autoplays"
            >
              <el-carousel-item
                v-for="(item, i) in goods.photo"
                :key="i"
                v-if="item !== ''"
              >
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item"
                  :preview-src-list="goods.photo"
                  v-if="item != '' && item.substr(item.lastIndexOf('.') + 1, 3) !== 'mp4'"
                >
                </el-image>
                <video
                  @play="handleplay"
                  @pause="handlepause"
                  :src="item"
                  id="videos"
                  ref="videos"
                  v-else-if="
                    item != '' && item.substr(item.lastIndexOf('.') + 1, 3) === 'mp4'
                  "
                  controls="controls"
                  preload
                ></video>
              </el-carousel-item>
            </el-carousel>
          </div>
          <ul>
            <li
              :class="{ actLi: index === $refs.carous.activeIndex }"
              v-for="(item, index) in goods.photo"
              :key="index"
              @click="checkImg(index)"
            >
              <img
                :src="item"
                alt=""
                v-if="item !== '' && item.substr(item.lastIndexOf('.') + 1, 3) !== 'mp4'"
              />
              <video
                :src="item"
                v-else-if="
                  item !== '' && item.substr(item.lastIndexOf('.') + 1, 3) === 'mp4'
                "
              ></video>
            </li>
          </ul>
        </div>
        <div class="main-body">
          <div class="site-header">
            <h3>
              {{ goods.goods_name }}
            </h3>
            <p>
              付款方式
              <span v-if="goods.pay_type === 1">一次性付全款</span>
              <span v-if="goods.pay_type === 2"
                >预付款{{ goods.pay_first_price }}%+尾款{{
                  100 - goods.pay_first_price
                }}%</span
              >
            </p>
            <ul>
              <li class="org">
                <img src="../../../assets/img/评分@2x.png" alt="" />评分
                {{ goods.evaluate }}
              </li>
              <li>
                <img
                  src="../../../assets/img/Iconly_Light-Outline_Show@2x.png"
                  alt=""
                />访问量 {{ goods.read_num }}
              </li>
              <li>
                <img
                  src="../../../assets/img/Iconly_Light-Outline_Filter 2@2x.png"
                  alt=""
                />销售 {{ goods.sell_num }}
              </li>
            </ul>
            <div class="tabs">
              <div
                :class="{ active: form.type === 1 }"
                :style="goods.is_sell === 0 ? 'width:100%' : ''"
                @click="checkTab(1)"
                v-if="goods.is_lease === 1"
              >
                租赁
              </div>
              <div
                :class="{ active: form.type === 2 }"
                :style="goods.is_lease === 0 ? 'width:100%' : ''"
                @click="checkTab(2)"
                v-if="goods.is_sell === 1"
              >
                购买
              </div>
            </div>
          </div>
          <div class="site-body">
            <el-form ref="form" :model="form" label-width="80px" label-position="left">
              <el-form-item label="价格">
                <div class="money">
                  <p>
                    ￥<span>{{ gprice }}</span>
                    {{ form.goods_sid.length > 0 ? "" : "起" }}
                  </p>
                </div>
              </el-form-item>
              <el-form-item label="配送至">
                <el-select
                  v-model="form.mitval"
                  placeholder="请选择"
                  @change="checkarea"
                  value-key="code"
                >
                  <el-option
                    v-for="item in mitArea"
                    :key="item.code"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="form.cityval"
                  placeholder="请选择"
                  @change="checkarea"
                  value-key="code"
                >
                  <el-option
                    v-for="item in city"
                    :key="item.code"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="form.areaval"
                  placeholder="请选择"
                  @change="checkarea"
                  value-key="code"
                >
                  <el-option
                    v-for="item in Areas"
                    :key="item.code"
                    :label="item.name"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品规格">
                <el-checkbox-group
                  v-model="form.goods_sid"
                  fill="#D8E5FF"
                  @change="getaddach"
                  text-color="#3d7eff"
                >
                  <el-checkbox-button
                    v-for="item in specs"
                    :key="item.specs_id"
                    :label="item"
                    >{{ item.specs_name }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="附加服务">
                <el-checkbox-group
                  v-model="form.goods_serid"
                  fill="#D8E5FF"
                  text-color="#3d7eff"
                >
                  <el-checkbox-button
                    v-for="s in serarr"
                    :key="s.service_id"
                    :label="s"
                    >{{ s.service_name }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="送达时间">
                <el-date-picker
                  v-model="form.timeer"
                  :picker-options="pickerOptions"
                  type="date"
                  @change="handleTime"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="使用天数"
                v-if="form.type === 1 && goods.is_lease === 1"
              >
                <el-input-number
                  v-model="form.daynum"
                  @change="handleChange"
                  :min="0"
                  :max="15"
                  size="mini"
                  label="描述文字"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="数量">
                <el-input-number
                  v-model="form.num"
                  :min="1"
                  @change="handleChanges"
                  :max="stock"
                  size="mini"
                  label="描述文字"
                ></el-input-number>
                <span class="stock">库存{{ stock }}件</span>
              </el-form-item>
              <el-form-item label="规格参数">
                <el-popover
                  placement="top"
                  popper-class="btnlist"
                  ref="btnlist"
                  trigger="click"
                >
                  <el-card class="box-card">
                    <div slot="header" class="clearfix">
                      <span>商品清单</span>
                      <i
                        class="el-icon-close"
                        @click="handleClose"
                        style="float: right"
                      ></i>
                    </div>
                    <div class="item" v-for="(o, index) in specs" :key="index">
                      <h3><span></span>{{ o.specs_name }}</h3>
                      <el-table
                        :data="o.sku"
                        :header-cell-style="{
                          color: ' #19293d',
                          'font-weight': 700,
                          'font-size': '14px',
                        }"
                        style="width: 100%"
                      >
                        <el-table-column prop="sku_name" label="单品名称">
                        </el-table-column>
                        <el-table-column prop="num" label="数量"> </el-table-column>
                        <el-table-column prop="model_name" label="型号">
                        </el-table-column>
                        <el-table-column prop="name" label="包装尺寸cm" min-width="120px">
                          <template slot-scope="scope">
                            {{ scope.row.long }}*{{ scope.row.width }}*{{
                              scope.row.height
                            }}
                          </template>
                        </el-table-column>
                        <el-table-column prop="net_weight" label="净重kg">
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-card>
                  <el-button slot="reference" type="text" style="color: #000"
                    >查看商品清单</el-button
                  >
                </el-popover>
              </el-form-item>
            </el-form>
            <div class="saves">请选择想要的商品</div>
            <p v-if="btnVis && form.area_id !== ''" style="color: red">
              选择的区域不在配送范围内
            </p>
            <div class="btnBox">
              <el-button-group>
                <el-button @click="paycenter" :disabled="btnVis || stock === 0"
                  >立即下单</el-button
                >
                <el-button type="primary" @click="joincar">加入购物车</el-button>
              </el-button-group>
              <div
                class="collection"
                @click="collection(goods.is_col)"
                v-if="goods.is_col === 1"
              >
                <img src="../../../assets/img/Iconly_Bold_Heart.png" alt="" />取消收藏
              </div>
              <div class="collection" @click="collection(goods.is_col)" v-else>
                <img src="../../../assets/img/Iconly_Two-tone_Profile.png" alt="" />收藏
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="multitable">
        <ul>
          <li :class="{ active: currIndexs === 0 }" @click="checkRel(0)">详情介绍</li>
          <li :class="{ active: currIndexs === 1 }" @click="checkRel(1)">用户须知</li>
          <li :class="{ active: currIndexs === 2 }" @click="checkRel(2)">用户评价</li>
        </ul>
        <div>
          <detail v-if="currIndexs === 0" :detail="goods.goods_text"></detail>
          <detail v-if="currIndexs === 1" :detail="goods.text"></detail>
          <evaluate v-if="currIndexs === 2"></evaluate>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="448px"
      class="print"
      top="10%"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="handleCloses"
    >
      <h3 slot="title">请阅读以下信息</h3>
      <div>
        <p>下单即表示同意以下信息：</p>
        <p v-for="item in inform" :key="item" v-html="item"></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloses">不同意</el-button>
        <el-button type="primary" @click="toplay()">同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import detail from "./detail.vue";
import evaluate from "./evaluate.vue";
import { message } from "../../../utils/resetMessage";
export default {
  components: {
    detail,
    evaluate,
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        num: 1,
        daynum: 1,
        goods_sid: [],
        goods_serid: [],
        area_id: "",
        timeer: "",
        type: 1,
        mitval: "",
        cityval: "",
        areaval: "",
      },
      goods: {},
      specs: [],
      currIndexs: 0,
      mitArea: [],
      Areas: [],
      city: [],
      serarr: [],
      btnVis: false,
      stock: 100,
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() < Date.now() - 8.64e7;   //禁用以前的日期，今天不禁用
          return time.getTime() < Date.now(); //禁用今天以及以前的日期
        },
      },
      autoplays: true,
      inform: null,
      phoneInex: null
    };
  },
  methods: {
    handleCloses() {
      this.dialogVisible = false;
    },
    handleplay() {
      this.autoplays = false;
    },
    handlepause() {
      this.autoplays = true;
    },
    checkImg(val) {
      this.$refs.carous.setActiveItem(val);
      if (
        this.goods.photo[val].substr(this.goods.photo[val].lastIndexOf(".") + 1, 3) ===
        "mp4"
      ) {
        // this.$refs.carous.setActiveItem(val)
        videos.currentTime = 0;
        videos.pause();
      }
    },
    handleChange(value) {
      this.getstock();
    },
    handleChanges(value) {},
    handleTime() {
      this.getstock();
    },
    checkTab(i) {
      this.form = {
        num: 1,
        daynum: 1,
        goods_sid: [],
        goods_serid: [],
        area_id: "",
        timeer: "",
        type: i,
      };
      this.serarr = [];
    },
    checkRel(i) {
      this.currIndexs = i;
    },
    handleClose() {
      this.$refs.btnlist.doClose();
    },
    paycenter() {
      const that = this;
      if (localStorage.getItem("member_id")) {
        if (
          that.form.goods_sid[0] &&
          that.form.area_id !== "" &&
          that.form.timeer !== ""
        ) {
          that.form.type === 1 ? (that.dialogVisible = true) : that.toplay();
        } else {
          document.getElementsByClassName("saves")[0].style.display = "block";
          document.getElementsByClassName("saves")[0].style.opacity = 0.5;
          setTimeout(() => {
            document.getElementsByClassName("saves")[0].style.opacity = 0;
            document.getElementsByClassName("saves")[0].style.display = "none";
          }, 1500);
        }
      } else {
        that.$router.push("/login");
      }
    },
    toplay() {
      const that = this;
      if (localStorage.getItem("member_id")) {
        if (
          that.form.goods_sid[0] &&
          that.form.area_id !== "" &&
          that.form.timeer !== ""
        ) {
          that.form.goods_id = that.$route.query.item;
          that.$store.commit("saveform", that.form);
          let arr = [];
          that.form.goods_serid.forEach((ele) => {
            arr.push(ele.service_id);
          });
          let goods_serid = arr.join(",");
          const data = {
            member_id: localStorage.getItem("member_id"),
            goods_sid: that.form.goods_sid[0].specs_id,
            goods_serid: goods_serid,
            car_num: that.form.num,
            areaid: that.form.area_id,
            goods_id: that.$route.query.item,
            type: that.form.type,
          };
          that.$http
            .postApi("/cargoodslist", data)
            .then((res) => {
              if (res.data.code === 200) {
                that.$store.commit("savegoods", res.data.data.list);
                that.$router.push("/order");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          document.getElementsByClassName("saves")[0].style.display = "block";
          document.getElementsByClassName("saves")[0].style.opacity = 0.5;
          setTimeout(() => {
            document.getElementsByClassName("saves")[0].style.opacity = 0;
            document.getElementsByClassName("saves")[0].style.display = "none";
          }, 1500);
        }
      } else {
        that.$router.push("/login");
      }
    },
    getNote() {
      const that = this;
      that.$http
        .postApi("/order_information", {})
        .then((res) => {
          if (res.data.code === 200) {
            that.inform = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    joincar() {
      const that = this;
      if (localStorage.getItem("member_id")) {
        if (that.form.goods_sid.length > 0) {
          const data = {
            goods_id: that.$route.query.item,
            type: that.form.type,
            member_id: localStorage.getItem("member_id"),
            goods_sid: that.form.goods_sid[0].specs_id,
            goods_serid: that.form.goods_serid,
            car_num: that.form.num,
          };
          that.$http
            .postApi("/carsadd", data)
            .then((res) => {
              if (res.data.code === 200) {
                message.success("成功加入购物车");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          message.error("请选择规格");
        }
      } else {
        that.$router.push("/login");
      }
    },
    collection(code) {
      const that = this;
      if (localStorage.getItem("member_id")) {
        const data = {
          goods_id: that.$route.query.item,
        };
        if (localStorage.getItem("token")) {
          data.member_id = localStorage.getItem("member_id");
        }
        if (code === 1) {
          that.$http
            .postApi("/del_collection", data)
            .then((res) => {
              if (res.data.code === 200) {
                that.goods.is_col = 0;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (code === 0) {
          that.$http
            .postApi("/set_collection", data)
            .then((res) => {
              if (res.data.code === 200) {
                that.goods.is_col = 1;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        that.$router.push("/login");
      }
    },
    getaddach(val) {
      if (val.length > 1) {
        val.splice(val[0], 1);
      }
      const that = this;
      that.form.goods_serid = [];
      if (val.length > 0) {
        const data = {
          goods_id: that.$route.query.item,
          specs_id: val[0],
        };
        that.$http
          .postApi("/get_goods_service", data)
          .then((res) => {
            if (res.data.code === 200) {
              that.serarr = res.data.data;
              that.getstock();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        that.serarr = [];
      }
    },
    getstock() {
      const that = this;
      if (
        that.form.goods_sid[0] &&
        that.form.timeer !== "" &&
        that.form.timeer !== null
      ) {
        const data = {
          specs_id: that.form.goods_sid[0].specs_id,
          begin_time: that.form.timeer,
          use_time: that.form.daynum,
        };
        that.$http
          .postApi("/get_goods_stock", data)
          .then((res) => {
            if (res.data.code === 200) {
              that.stock = res.data.data.num;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getDetail() {
      const that = this;
      const data = {
        goods_id: that.$route.query.item,
      };
      if (localStorage.getItem("token")) {
        data.member_id = localStorage.getItem("member_id");
      }
      that.$http
        .postApi("/goods_item", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.goods = res.data.data.goods;
            that.specs = res.data.data.specs;
            if (that.goods.is_lease === 0) {
              that.checkTab(2);
            } else if (that.goods.is_sell === 0) {
              that.checkTab(1);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkarea(val) {
      const that = this;
      if (val.type !== 3) {
        this.getArea(val.code, val.type);
      }
      const data = {
        goods_id: that.$route.query.item,
        type: that.form.type,
        area_id: val.code,
      };
      that.$http
        .postApi("/get_goods_freight", data)
        .then((res) => {
          if (res.data.code === 200) {
            if (val.type === 3) {
              that.form.area_id = val.code;
            }
            if (res.data.data.status) {
              // if (that.stock !== 0) {
              that.btnVis = false;
              // }
            } else {
              that.btnVis = true;
              // that.form.area_id = ''
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getArea(code, type) {
      const that = this;
      const data = {
        area_code: code || 0,
        type: type || 0,
      };
      that.$http
        .postApi("/getbusarea", data)
        .then((res) => {
          if (res.data.code === 200) {
            if (type === 1) {
              that.form.cityval = "";
              that.form.areaval = "";
              that.city = res.data.data;
            } else if (type === 2) {
              that.form.areaval = "";
              that.Areas = res.data.data;
            } else {
              that.mitArea = res.data.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    gprice() {
      let order = 0;
      this.form.goods_serid.forEach((ele) => {
        order += Math.floor(Number(ele.price) * 100);
      });
      if (this.form.type === 1) {
        let money = 0;
        if (this.goods.lease_type === 1) {
          money =
            this.form.goods_sid.length > 0
              ? Math.floor(
                  Number(this.form.goods_sid[0].lease_account) *
                    this.form.num *
                    this.form.daynum *
                    100 +
                    order
                ) / 100
              : Math.floor(
                  Number(this.goods.min_lease) * this.form.num * this.form.daynum * 100 +
                    order
                ) / 100;
        } else if (this.goods.lease_type === 2) {
          money =
            this.form.goods_sid.length > 0
              ? Math.floor(
                  Number(this.form.goods_sid[0].lease_account) * this.form.num * 100 +
                    order
                ) / 100
              : Math.floor(Number(this.goods.min_lease) * this.form.num * 100 + order) /
                100;
        }
        return money;
      } else if (this.form.type === 2) {
        let money =
          this.form.goods_sid.length > 0
            ? Math.floor(
                Number(this.form.goods_sid[0].sell_account) * this.form.num * 100 + order
              ) / 100
            : Math.floor(Number(this.goods.min_sell) * this.form.num * 100 + order) / 100;
        return money;
      }
    },
  },
  mounted() {
    this.getDetail();
    this.getArea();
    this.getNote();
  },
};
</script>

<style lang="scss" scoped>
.detail {
  background-color: white;
  .navigation {
    margin: 35px 0;
    .el-breadcrumb__item {
      font-size: 16px;
      color: #101b28;
    }
  }
  .domain {
    display: flex;
    .main-nav {
      .larger,
      /deep/.el-carousel__container {
        width: 430px;
        height: 430px;
        margin-bottom: 12px;
        video,
        img {
          width: 100%;
          height: 100%;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
        max-width: 430px;
        li {
          width: 80px;
          height: 80px;
          video,
          img {
            width: 100%;
            height: 100%;
          }
        }
        .actLi {
          border: 1px solid #3d7eff;
          width: 79px;
          height: 79px;
        }
        // li + li {
        //   margin-left: 7px;
        // }
      }
    }
    .main-body {
      margin-left: 24px;
      width: 100%;
      .site-header {
        h3 {
          font-size: 17px;
          font-weight: 700;
          color: #000000;
          line-height: 25px;
        }
        p {
          font-size: 15px;
          font-weight: 700;
          color: #ee4545;
          span {
            font-size: 14px;
            font-weight: 500;
            color: #ee4545;
            margin-left: 10px;
          }
        }
        ul {
          display: flex;
          margin-top: 19px;
          margin-bottom: 12px;
          li {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: #97a5b7;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
            }
          }
          li + li {
            margin-left: 61px;
          }
          li.org {
            color: #ff863d;
          }
        }
        .tabs {
          display: flex;
          width: 100%;
          div {
            height: 54px;
            width: 49%;
            border: 1px solid #f5f5f6;
            border-radius: 3px;
            font-weight: 700;
            text-align: CENTER;
            color: #101b28;
            line-height: 56px;
            cursor: pointer;
          }
          .active {
            background: rgba(61, 126, 255, 0.1);
            color: #3d7eff;
            border-bottom: 2px solid #3d7eff;
          }
        }
      }
      .site-body {
        .el-form {
          margin-top: 16px;
          margin-left: 16px;
          .money {
            display: flex;
            align-items: center;
            p {
              color: #ff863d;
              margin-right: 10px;
              span {
                font-size: 19px;
                font-weight: 700;
                color: #ff863d;
              }
            }
            span {
              font-size: 14px;
              color: #101b28;
            }
          }
          /deep/.el-radio-button__inner,
          /deep/.el-checkbox-button__inner {
            min-width: 131px;
            height: 36px;
            background: #f5f5f6;
            border-radius: 2px;
            border: none;
          }
          .is-active /deep/.el-radio-button__inner {
            background-color: rgb(216, 229, 255);
            border-color: rgb(216, 229, 255);
            color: rgb(61, 126, 255);
            box-shadow: rgb(216, 229, 255) -1px 0px 0px 0px;
          }
          .el-radio-button,
          .el-checkbox-button {
            margin-right: 8px;
          }
          .stock {
            font-size: 13px;
            color: #97a5b7;
            margin-left: 12px;
          }
        }
        .btnBox {
          display: flex;
          .el-button-group > .el-button {
            // width: 184px;
            min-width: 170px;
            height: 52px;
            border: 1px solid #e7eaed;
            border-radius: 3px;
          }
          .collection {
            margin-left: 32px;
            font-weight: 700;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #101b28;
            line-height: 18px;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  .multitable {
    width: 100%;
    ul {
      margin: 60px 0 30px;
      display: flex;
      background: #f5f5f6;
      border: 1px solid #e7eaed;
      li {
        width: 170px;
        height: 52px;
        text-align: center;
        border: 1px solid #f5f5f6;
        border-radius: 2px;
        line-height: 54px;
        cursor: pointer;
      }
      li.active {
        background: #3d7eff;
        color: white;
      }
    }
  }
}
.saves {
  width: 179px;
  height: 54px;
  // opacity: 0.5;
  background: #101b28;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  line-height: 54px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 140px;
  display: none;
  opacity: 0;
  transition: opacity 2s ease;
  z-index: 99999 !important;
}
.print {
  /deep/.el-dialog__header {
    height: 64px;
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 18px 36px;
  }
  h3 {
    font-size: 17px;
    font-weight: 500;
    color: #101b28;
    line-height: 64px;
    text-align: center;
  }
  div {
    height: 300px;
    overflow-y: auto;
    p {
      font-size: 14px;
      color: #101b28;
      line-height: 16px;
    }
    p + p {
      margin-top: 22px;
    }
  }
  div::-webkit-scrollbar-thumb {
    width: 3px;
  }
  div::-webkit-scrollbar-button {
    display: none;
    width: 0;
  }
  div::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
    // background: #007acc;
    border-radius: 3px;
  }
  /*定义滚动条轨道（凹槽）样式*/
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* 较少使用 */
    border-radius: 3px;
  }
  /*定义滑块 样式*/
  div::-webkit-scrollbar-thumb {
    border-radius: 3px;
    height: 70px; /* 滚动条滑块长度 */
    background-color: #ccc;
  }

  .el-button {
    border-radius: 3px;
  }
}
</style>
<style lang="scss">
.el-popper.btnlist {
  padding: 0;
  border: none;
  .box-card {
    width: 480px;
    .el-card__header {
      text-align: center;
      font-size: 17px;
      font-weight: 500;
      color: #101b28;
      padding: 10px 15px;
      line-height: 32px;
      i {
        font-size: 32px;
      }
    }
    .item {
      margin-bottom: 12px;
      h3 {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        color: #101b28;
        margin-bottom: 10px;
        span {
          display: inline-block;
          width: 2px;
          height: 14px;
          background: #3d7eff;
          border-radius: 14px;
          margin-right: 4px;
        }
      }
      .el-table td,
      .el-table th {
        padding: 0;
      }
      .el-table td,
      .el-table th.is-leaf {
        border-bottom: none;
      }
      .el-table::before {
        height: 0;
      }
    }
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
}
</style>
