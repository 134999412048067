<template>
  <div class="activities content">
    <div class="crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-container>
      <el-aside width="142px">
        <ul>
          <li
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ active: currIndex === index }"
            @click="changetabs(index)"
          >
            <img :src="item.img" alt="" />
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </el-aside>
      <el-main>
        <myProfile v-if="currIndex === 0"></myProfile>
        <myOrders v-if="currIndex === 1"></myOrders>
        <myFavorites v-if="currIndex === 2"></myFavorites>
        <cipher v-if="currIndex === 3"></cipher>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import cipher from './cipher.vue';
import myFavorites from './myFavorites.vue';
import myOrders from './myOrders.vue';
import myProfile from './myProfile.vue';
export default {
  components: {
    myOrders,
    myFavorites,
    myProfile,
    cipher
  },
  data () {
    return {
      tabs: [{
        img: require('../../assets/img/Iconly_Two-tone.png'),
        title: '我的信息'
      }, 
      {
        img: require('../../assets/img/Iconly_Two-tone_Profile3@2x.png'),
        title: '我的订单'
      }, 
      {
        img: require('../../assets/img/Iconly_Two-tone_Profile@2x.png'),
        title: '我的收藏'
      }, {
        img: require('../../assets/img/Iconly_Two-tone_Profile_2@2x.png'),
        title: '修改密码'
      }],
      currIndex: 0
    }
  },
  methods: {
    handleClick (tab, event) {
    },
    changetabs (i) {
      this.currIndex = i
      if (i === 0) {
        this.$router.push('/myProfile')
      } else if (i === 2) {
        this.$router.push('/myFavorites')
      } else if (i === 1) {
        this.$router.push('/myOrders')
      } else if (i === 3) {
        this.$router.push('/cipher')
      }
    }
  },
  watch: {
     '$route.path':{
      handler(val,oldval){
        if (val === '/myProfile') {
          this.currIndex = 0
        } else if (val === '/myFavorites') {
          this.currIndex = 2
        } else if (val === '/myOrders') {
          this.currIndex = 1
        } else if (val === '/cipher') {
          this.currIndex = 3
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.activities {
  .crumb {
    padding: 26px 0;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .el-aside {
    margin-right: 16px;
    background-color: #fff;
    height: 665px;
    ul {
      li {
        padding: 0 16px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          color: #101b28;
        }
      }
      li.active {
        background: rgba(61, 126, 255, 0.1);
        box-shadow: 2px 0px 0px 0px #3d7eff inset;
      }
    }
  }
  .el-main {
    padding: 0;
  }
}
</style>