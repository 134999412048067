<template>
  <div>
    <div class="tabls">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="-1"></el-tab-pane>
        <el-tab-pane
          :label="'待支付（' + paycount + '）'"
          name="0"
        ></el-tab-pane>
        <el-tab-pane
          :label="'待签收（' + signcount + '）'"
          name="3"
        ></el-tab-pane>
        <el-tab-pane
          :label="'待评价（' + evaluatecount + '）'"
          name="10"
        ></el-tab-pane>
        <el-tab-pane
          :label="'已完成（' + complatecount + '）'"
          name="7"
        ></el-tab-pane>
      </el-tabs>
      <el-input
        placeholder="请输入内容"
        v-if="activeName === '-1'"
        v-model="input3"
        class="input-with-select"
      >
        <el-select v-model="select" slot="prepend" placeholder="请选择">
          <el-option label="订单编号" value="2"></el-option>
          <el-option label="商品名称" value="1"></el-option>
        </el-select>
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="search"
        ></el-button>
      </el-input>
    </div>
    <ul v-if="list.length > 0">
      <li v-for="item in list" :key="item.order_sn">
        <div class="lease">
          <div class="lease_order">
            <img
              v-if="item.type === 1"
              src="../../assets/img/Frame 534@2x.png"
              alt=""
            />
            <img
              v-if="item.type === 2"
              src="../../assets/img/Frame 534_1@2x.png"
              alt=""
            />
            <span v-text="item.type === 1 ? '租赁订单' : '购买订单'"></span>
            <p style="color: #3d7eff">使用时间：{{ item.use_starttime }}</p>
            <p>订单编号：{{ item.order_sn }}</p>
            <p>下单时间：{{ item.create_time }}</p>
            <p
              v-if="item.order_status === 0"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待支付
            </p>
            <p
              v-if="item.order_status === 1"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待审核
            </p>
            <p
              v-if="item.order_status === 2"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待发货
            </p>
            <p
              v-if="item.order_status === 3"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待签收
            </p>
            <p
              v-if="item.order_status === 4"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待付尾款
            </p>
            <p
              v-if="item.order_status === 5"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:尾款待审核
            </p>
            <p
              v-if="item.order_status === 6"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:待回收
            </p>
            <p
              v-if="item.order_status === 7"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:已完成
            </p>
            <p
              v-if="item.order_status === 8"
              style="color: #ff863d; margin-left: auto"
            >
              订单状态:已取消
            </p>
            <!-- <p
              v-if="item.refund_status === 0 && item.order_status > 0"
              style="color: #ff863d; margin-left: auto"
            >
              未退款
            </p> -->
            <p
              v-if="item.refund_status === 1 && item.order_status > 0"
              style="color: #ff863d; margin-left: auto"
            >
              退款审核中
            </p>
            <p
              v-if="item.refund_status === 2 && item.order_status > 0"
              style="color: #ff863d; margin-left: auto"
            >
              退款驳回
            </p>
            <p
              v-if="item.refund_status === 3 && item.order_status > 0"
              style="color: #ff863d; margin-left: auto"
            >
              退款已退款
            </p>
          </div>
          <div class="lease_detail">
            <ul>
              <li
                v-for="g in item.goods_info"
                :key="g.goods_id"
              >
                <div class="detailed">
                  <div>
                    <img
                      :src="g.goods_thumb"
                      alt=""
                      v-if="
                        g.goods_thumb &&
                        g.goods_thumb !== '' &&
                        g.goods_thumb.substr(
                          g.goods_thumb.lastIndexOf('.') + 1,
                          3
                        ) !== 'mp4'
                      "
                    />
                    <video
                      :src="g.goods_thumb"
                      id="videos"
                      v-else-if="
                        g.goods_thumb &&
                        g.goods_thumb !== '' &&
                        g.goods_thumb.substr(
                          g.goods_thumb.lastIndexOf('.') + 1,
                          3
                        ) === 'mp4'
                      "
                      preload
                    ></video>
                  </div>
                  <div class="angeforderte">
                    <div class="state">
                      <div class="goods_name">
                        {{ g.goods_name }}
                      </div>
                      <div class="divflex" style="flex: 1 auto">
                        <p class="w_180 gury">
                          {{ g.specs_name }}x{{ g.goods_num }}
                        </p>
                        <p class="gury">￥{{ g.goods_price }}</p>
                        <p class="w_80">
                          ￥{{ Math.floor(Number(g.goods_price) * g.goods_num * 100) / 100 }}
                        </p>
                      </div>
                    </div>
                    <div class="state">
                      <div class="w_202">附加服务</div>
                      <div style="flex: 1 auto">
                        <div
                          class="divflex"
                          v-for="(s, i) in g.service_info"
                          :key="i"
                        >
                          <p class="gury w_180">{{ s.service_name }}x1</p>
                          <p class="gury">￥{{ s.service_price }}</p>
                          <p class="w_80">￥{{ s.service_price }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="state">
                      <div class="w_202"></div>
                      <div style="flex: 1 auto">
                        <div class="divflex">
                          <p class="gury w_180">运费</p>
                          <p class="gury">￥{{g.express_price}}</p>
                          <p class="w_80">￥{{g.express_price}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="total">
                  <p>合计：￥{{ item.total_money }}</p>
                  <p v-if="item.pay_type === 1">微信支付</p>
                  <p v-if="item.pay_type === 2">支付宝支付</p>
                  <p v-if="item.pay_type === 3">银行转账</p>
                </div>
              </li>
            </ul>
            <div class="btnBox">
              <el-button
                type="primary"
                size="mini"
                plain
                @click="todetil(item.order_sn)"
                >查看订单</el-button
              >
              <el-button
                plain
                size="mini"
                v-if="item.order_status === 0"
                @click="cancelOrder(item.order_sn)"
                >取消订单</el-button
              >
              <el-button
                type="warning"
                size="mini"
                v-if="item.order_status === 0"
                @click="topay(item)"
                >去支付</el-button
              >
              <el-button
                plain
                size="mini"
                v-if="
                  item.order_status > 0 &&
                  item.order_status < 3 &&
                  (item.refund_status === 0 || item.refund_status === 2) &&
                  item.refunds_num < 3
                "
                @click="refund(item.order_sn)"
                >申请退款</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="qhconfirm(item.order_sn)"
                v-if="item.order_status === 3"
                >确认签收</el-button
              >
              <el-button
                type="warning"
                size="mini"
                class="weiBtn"
                @click="topay(item)"
                v-if="item.order_status === 4"
                >去支付尾款</el-button
              >
              <span v-if="item.order_status === 5">尾款待审核</span>
              <span v-if="item.order_status === 6">待回收</span>
              <!-- <span v-if="item.refund_status === 1">退款审核中</span>
              <span v-if="item.refund_status === 2">退款驳回</span>
              <span v-if="item.refund_status === 3">退款完成</span> -->
              <el-button
                type="warning"
                size="mini"
                @click="todetil(item.order_sn)"
                v-if="item.order_status === 7 && !item.evaluate_time"
                >去评价</el-button
              >
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="empty" v-else>
      <img src="../../assets/img/Frame 785.png" alt="" />
      <h4>您还没有相关订单</h4>
      <p>可以去看看有哪些想买的～</p>
      <el-button type="primary" @click="$router.push('/goodslist')">前往下单</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '-1',
      list: [],
      paycount: 0,
      signcount: 0,
      complatecount: 0,
      evaluatecount: 0,
      select: '1',
      input3: '',
      vals: {},
    }
  },
  methods: {
    handleClick () {
      if (this.activeName !== '-1') {
        this.getdata(Number(this.activeName))
      } else {
        this.getdata()
      }
    },
    todetil (sn) {
      let routeData = this.$router.resolve({ path: '/schedule', query: { sn: sn } });
      window.open(routeData.href, '_blank');
    },
    topay (goods) {
      let params = {
        total_price: goods.frist_money,
        orderNo: goods.order_sn,
        pay_type: goods.pay_type,
        order_status: goods.order_status,
        type: 2
      }
      if (params.order_status === 4) {
        params.total_price = goods.two_money
      }
      let g = {}
      if (goods.type === 1) {
        g.lease = JSON.parse(JSON.stringify(goods.goods_info))
        g.lease.forEach((ele, index) => {
          ele.infoid = index
          ele.car_num = ele.goods_num
        })
      } else if (goods.type === 2) {
        g.sell = JSON.parse(JSON.stringify(goods.goods_info))
        g.sell.forEach((ele, index) => {
          ele.infoid = index
          ele.car_num = ele.goods_num
        })
      }
      this.$store.commit('savegoods', g)
      sessionStorage.setItem('pay', JSON.stringify(params))
      this.$router.push('/payment')
    },
    cancelOrder (sn) {
      const data = {
        order_sn: sn,
        member_id: localStorage.getItem('member_id'),
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$http.postApi('/ordercancel', data).then(res => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.getdata()
            loading.close();
          }, 1000);
        }
      }).catch(err => {
        loading.close();
        console.log(err)
      })
    },
    search () {
      let val = {}
      if (this.input3 !== '') {
        val.opt = this.select
        val.keyword = this.input3
      }
      this.vals = val
      this.getdata()
    },
    getdata (msg) {
      const that = this
      const data = {
        member_id: localStorage.getItem('member_id'),
        page: '1',
        limit: 99
      }
      Object.assign(data, that.vals)
      if (msg !== -1) {
        data.order_status = msg
      }
      that.$http.postApi('/orderlist', data).then(res => {
        if (res.data.code === 200) {
          console.log(res)
          that.list = res.data.data.list
          that.paycount = res.data.data.paycount
          that.signcount = res.data.data.signcount
          that.evaluatecount = res.data.data.evaluatecount
          that.complatecount = res.data.data.complatecount
        }
      }).catch(err => {
        console.log(err)
      })
    },
    refund (sn) {
      const that = this
      that.$prompt('请输入退款原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        const data = {
          order_sn: sn,
          member_id: localStorage.getItem('member_id'),
          refund_reson: value
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$http.postApi('/orderapplyrefund', data).then(res => {
          if (res.data.code === 200) {
            setTimeout(() => {
              that.getdata()
              loading.close();
            }, 1000);
          }
        }).catch(err => {
          loading.close();
          console.log(err)
        })
      }).catch(() => {
      });
    },
    qhconfirm (sn) {
      const that = this
      that.$confirm('是否确认签收? \n 签收后不可申请退款', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          order_sn: sn,
          member_id: localStorage.getItem('member_id'),
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        that.$http.postApi('/ordersign', data).then(res => {
          if (res.data.code === 200) {
            setTimeout(() => {
              that.getdata()
              loading.close();
            }, 1000);
          }
        }).catch(err => {
          loading.close();
          console.log(err)
        })
      }).catch(() => {
      });
    }
  },
  mounted () {
    this.getdata()
  }
}
</script>

<style lang="scss" scoped>
.tabls {
  display: flex;
  align-items: center;
  padding: 0 18px;
  background-color: #fff;
  margin-bottom: 8px;
  /deep/.el-tabs__header {
    margin-bottom: 8px;
  }
  /deep/.el-tabs__nav-wrap::after {
    display: none;
  }
  /deep/.el-select .el-input {
    width: 105px;
  }
  .input-with-select {
    // margin-bottom: 10px;
    width: 40%;
    margin-left: auto;
    /deep/.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    /deep/.el-input__icon {
      line-height: 32px;
    }
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
  /deep/.el-input-group__append,
  /deep/.el-input-group__prepend {
    border-radius: 2px;
    background-color: #fff;
  }
}

ul {
  li {
    .lease {
      border: 1px solid #e7eaed;
      background-color: #fff;
      margin-bottom: 12px;
      .lease_order {
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 53px;
        background: linear-gradient(
          180deg,
          rgba(61, 126, 255, 0.1),
          rgba(61, 126, 255, 0) 100%
        );
        border-bottom: 1px solid #f5f5f6;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          font-size: 18px;
          margin-left: 8px;
          color: #101b28;
        }
        p {
          margin-left: 16px;
          font-size: 14px;
        }
      }
      .lease_detail {
        display: flex;
        ul {
          flex: 1 auto;
          padding: 0 24px;
          li {
            margin-top: 16px;
            display: flex;
            .detailed {
              flex: 1 auto;
              display: flex;
              border-bottom: 1px solid #f5f5f6;
              video,
              img {
                width: 88px;
                height: 88px;
                margin-right: 16px;
              }
              .angeforderte {
                width: 100%;
                .state {
                  display: flex;
                  margin-bottom: 16px;
                  .divflex {
                    display: flex;
                    justify-content: space-between;
                  }
                  .divflex + .divflex {
                    margin-top: 16px;
                  }
                  div {
                    font-size: 14px;
                    font-weight: 500;
                    color: #101b28;
                    line-height: 20px;
                  }
                }
                .w_202 {
                  width: 202px;
                }
                .w_180 {
                  width: 180px;
                }
                .w_80 {
                  width: 16%;
                }
                .goods_name {
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  width: 192px;
                  padding-right: 10px;
                }
              }
            }
            .total {
              margin: auto 0;
              p {
                text-align: right;
                font-size: 14px;
                color: #101b28;
                &:first-child {
                  font-weight: 700;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
        .btnBox {
          width: 80px;
          margin: auto 0;
          padding: 10px 0;
          margin-right: 14px;
          .el-button {
            width: 80px;
            border-radius: 2px;
          }
          .weiBtn {
            padding: 7px 9px;
          }
          .el-button + .el-button {
            margin-left: 0;
            margin-top: 13px;
          }
        }
      }
    }
    .gury {
      color: #97a5b7;
    }
  }
}
.empty {
  margin-top: 16vh;
  text-align: center;
  h4 {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #525b66;
    line-height: 28px;
  }
  p {
    font-size: 14px;
    color: #97a5b7;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .el-button {
    width: 144px;
    height: 40px;
    border-radius: 2px;
  }
}
.el-button--warning {
  background-color: #ff863c;
  border-color: #ff863c;
}
</style>