<template>
  <div class="activities content">
    <div class="crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-if="$route.query.id === '276'"
          >公告活动</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="$route.query.id === '277'"
          >帮助中心</el-breadcrumb-item
        >
      </el-breadcrumb>
      <el-input
        placeholder="请输入内容"
        v-model="input3"
        style="margin-left: auto; width: 30%"
        class="input-with-select"
      >
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-container>
      <el-aside width="142px">
        <ul>
          <li
            v-for="item in tabs"
            :key="item.type_id"
            :class="{ active: currIndex === item.type_id }"
            @click="changeTabs(item.type_id)"
          >
            <span>{{ item.type_name }}</span>
          </li>
        </ul>
      </el-aside>
      <el-main>
        <ul>
          <li
            v-for="item in tableData"
            :key="item.code"
            @click="todetail(item.code)"
          >
            <div class="imgleft">
              <img :src="item.cover_photo" alt="" />
            </div>
            <div class="textright">
              <h4>
                <img
                  src="../../assets/img/Frame 838.png"
                  alt=""
                  v-if="item.is_top === 1"
                />
                {{ item.title }}<span>{{ item.create_time }}</span>
              </h4>
              <p>{{item.summary}}</p>
            </div>
          </li>
        </ul>
      </el-main>
    </el-container>
    <div class="demonstration">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabs: [],
      currIndex: 0,
      page: 1,
      tableData: [],
      total: 0,
      input3: ''
    }
  },
  methods: {
    handleCurrentChange (val) {
      this.page = val
      this.getdata()
    },
    changeTabs (i) {
      this.currIndex = i
      this.getdata()
    },
    todetail (code) {
      let routeData = this.$router.resolve({ path: '/actProfile', query: { id: this.$route.query.id, item: code } });
      window.open(routeData.href, '_blank');
    },
    gettype () {
      const that = this
      const data = {
        frame_id: that.$route.query.id
      }
      that.$http.postApi('/news_type', data).then(res => {
        if (res.data.code === 200) {
          that.tabs = res.data.data.type
          that.currIndex = res.data.data.type[0].type_id
          that.getdata()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getdata () {
      const that = this
      const data = {
        page: that.page,
        pagesize: 6,
        frame_id: that.$route.query.id,
        type_id: that.currIndex
      }
      that.$http.postApi('/news', data).then(res => {
        if (res.data.code === 200) {
          that.tableData = res.data.data.list
          that.total = res.data.data.count
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.gettype()
  }
}
</script>

<style lang="scss" scoped>
.activities {
  .crumb {
    display: flex;
    align-items: center;
    padding: 26px 0;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .el-aside {
    margin-right: 16px;
    background-color: #fff;
    min-height: 627px;
    ul {
      li {
        padding: 0 16px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-weight: 700;
          color: #101b28;
        }
      }
      li.active {
        background: rgba(61, 126, 255, 0.1);
        box-shadow: 2px 0px 0px 0px #3d7eff inset;
      }
    }
  }
  .el-main {
    background-color: #fff;
    padding: 16px;
    li {
      display: flex;
      .imgleft img {
        width: 124px;
        height: 80px;
      }
      .textright {
        margin-left: 16px;
        h4 {
          font-size: 14px;
          font-weight: 500;
          color: #101b28;
          line-height: 18px;
          margin-bottom: 18px;
          span {
            font-size: 12px;
            color: #000000;
            line-height: 18px;
            float: right;
          }
          img {
            vertical-align: middle;
          }
        }
        p {
          width: 828px;
          font-size: 12px;
          color: #101b28;
          line-height: 18px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
    li + li {
      margin-top: 23px;
    }
  }
  .demonstration {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
}
</style>