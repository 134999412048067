<template>
  <div class="evaluate">
    <div class="levels">
      <span @click="checktype(1)" :class="{act:type === 1}">全部</span>
      <span @click="checktype(2)" :class="{act:type === 2}">四星五星</span>
      <span @click="checktype(3)" :class="{act:type === 3}">三星</span>
      <span @click="checktype(4)" :class="{act:type === 4}">一星二星</span>
    </div>
    <ul>
      <li v-for="(item,index) in list" :key="index">
        <div class="site_left">
          <el-avatar
            :src="item.pic"
            v-if="item.pic"
          ></el-avatar>
          <el-avatar
            v-else
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          ></el-avatar>
        </div>
        <div class="site_right">
          <h3>{{item.nickname}}</h3>
          <div class="rate">
            <el-rate v-model="item.score" disabled text-color="#ff9900">
            </el-rate>
            <span>{{item.create_time}}</span>
          </div>
          <p>
            {{item.text}}
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: 3,
      type: 1,
      page: 1,
      list: []
    }
  },
  methods: {
    checktype(val) {
      this.type = val
      this.getdata()
    },
    getdata() {
      const that = this
      const data = {
        goods_id: that.$route.query.item,
        type: that.type,
        page: that.page
      }
      that.$http.postApi('/get_goods_evaluate', data).then(res => {
        if (res.data.code === 200) {
          that.list = res.data.data.evaluate
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.getdata()
  }
}
</script>

<style lang="scss" scoped>
.evaluate {
  .levels {
    height: 32px;
    background: #f5f5f6;
    padding: 0 12px;
    margin-bottom: 24px;
    span {
      margin-right: 40px;
      font-size: 14px;
      font-weight: 500;
      color: #101b28;
      line-height: 32px;
      cursor: pointer;
    }
    span.act{
      color: #3d7eff;
    }
  }
  ul{
    li{
      display: flex;
      border-bottom: 1px solid #f5f5f6;
      margin-bottom: 24px;
      padding-bottom: 5px;
      .site_left{
        .el-avatar{
          width: 32px;
          height: 32px;
        }
        margin-right: 30px;
      }
      .site_right{
        h3{
          font-size: 14px;
          font-weight: 700;
          color: #101b28;
        }
        p{
          font-size: 14px;
          color: #101b28;
        }
        .rate{
          display: flex;
          align-items: center;
          span{
            font-size: 10px;
            color: #97a5b7;
          }
        }
      }
    }
  }
}
</style>