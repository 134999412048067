<template>
  <div class="login">
    <div class="header">
      <div class="head content">
        <img
          src="../assets/img/Component 1.png"
          @click="$router.push('/')"
          alt=""
        />
        <div></div>
        <p>登录/注册</p>
      </div>
    </div>
    <div class="main">
      <div class="mainbox">
        <div class="mleft">
          <div>
            <h3>一站式展会租赁平台</h3>
            <p>海量丰富的商品 | 精选商品一键直达</p>
          </div>
          <img src="../assets/img/yuansu.png" alt="" />
        </div>
        <div class="mright">
          <div class="title">
            <div :class="{ active: currIndex === 1 }" @click="switchLab(1)">
              <img
                src="../assets/img/Iconly_Light-Outline_Profile1@2x.png"
                alt=""
              />
              <span>账号密码登录</span>
            </div>
            <div :class="{ active: currIndex === 2 }" @click="switchLab(2)">
              <img
                src="../assets/img/Iconly_Light-Outline_Password@2x.png"
                alt=""
              />
              <span>验证码登录-注册</span>
            </div>
          </div>
          <div class="form" v-if="currIndex === 1">
            <h3>密码登录</h3>
            <div class="wraning" v-show="dialogVisable">
              <i class="el-icon-warning"></i>
              <span> {{ wranText }}</span>
            </div>
            <el-input
              v-model="form.account"
              placeholder="请输入手机号"
            ></el-input>
            <el-input
              v-model="form.password"
              type="password"
              placeholder="请输入登录密码"
            ></el-input>
            <el-button type="primary" @click="login">登录</el-button>
            <el-checkbox v-model="checked" @change="checklab"
              >我已阅读同意并遵守平台<span
                class="blue"
                @click.stop="tolink('/agreement')"
                >《用户协议》</span
              >及<span class="blue" @click.stop="tolink('/policy')"
                >《隐私政策》</span
              ></el-checkbox
            >
          </div>
          <div class="form" v-if="currIndex === 2">
            <h3>验证码登录</h3>
            <p>未注册用户将自动注册</p>
            <div class="wraning" v-show="dialogVisable">
              <i class="el-icon-warning"></i>
              <span> {{ wranText }}</span>
            </div>
            <div style="position: relative">
              <el-input
                v-model="form.account"
                placeholder="请输入手机号"
              ></el-input>
              <el-button type="text" class="code" @click="sendcode">{{
                code
              }}</el-button>
            </div>
            <el-input
              v-model="form.msgcode"
              placeholder="请输入验证码"
            ></el-input>
            <el-button type="primary" @click="login">登录</el-button>
            <el-checkbox v-model="checked" @change="checklab"
              >我已阅读同意并遵守平台<span
                class="blue"
                @click.stop="tolink('/agreement')"
                >《用户协议》</span
              >及<span class="blue" @click.stop="tolink('/policy')"
                >《隐私政策》</span
              ></el-checkbox
            >
          </div>
        </div>
      </div>
    </div>
    <div class="foot">
      <p>
        <a @click="topage('/pc_agree')">用户协议</a><span></span
        ><a @click="topage('/pc_policy')">隐私政策</a><span></span
        ><a @click="topage('/activity', '277')">帮助中心</a>
      </p>
      <!-- <p>Copyright© 青岛元罡科技有限公司 版权所有 备案号：鲁ICP备19029735号-1</p> -->
      <p>
        Copyright© 青岛七星国际会展科技有限公司 版权所有 备案号：<a
          href="https://beian.miit.gov.cn"
          target="_blank"
          >鲁ICP备15022445号-2</a
        >
      </p>
    </div>
    <div class="bg">
      <img src="../assets/img/bg.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      form: {
        account: "",
        password: "",
        msgcode: "",
      },
      code: "发送验证码",
      currIndex: 1,
      dialogVisable: false,
      wranText: "",
    };
  },
  methods: {
    tolink(link) {
      let routeData = this.$router.resolve({ path: link });
      window.open(routeData.href, "_blank");
    },
    switchLab(lab) {
      this.currIndex = lab;
    },
    checklab(data) {
      if (data) {
        this.dialogVisable = false;
      }
    },
    topage(page, id, item) {
      let routeData = this.$router.resolve({
        path: page,
        query: { id: id, item: item },
      });
      window.open(routeData.href, "_blank");
    },
    getTel() {
      const that = this;
      that.$http
        .postApi("/PcBusofficerlist", {})
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              res.data.data.list.forEach((ele) => {
                that.handleConfirms(ele.tengcloudid);
              });
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleConfirms(id) {
      if (id !== "@TIM#SYSTEM") {
        this.$store
          .dispatch("checkoutConversation", `C2C${id}`)
          .then(() => {})
          .catch(() => {
            this.$store.commit("showMessage", {
              message: "没有找到该用户",
              type: "warning",
            });
          });
      } else {
        this.$store.commit("showMessage", {
          message: "没有找到该用户",
          type: "warning",
        });
      }
    },
    login() {
      const that = this;
      if (that.checked) {
        if (that.form.account === "") {
          that.wranText = "请填写手机号";
          that.dialogVisable = true;
        } else if (that.form.password === "" && that.form.msgcode === "") {
          that.wranText =
            that.form.password === "" ? "请填写密码" : "请填写验证码";
          that.dialogVisable = true;
        } else {
          that.dialogVisable = false;
          const data = {
            account: that.form.account,
            type: that.currIndex === 1 ? 2 : 1,
          };
          if (that.currIndex === 1) {
            data.password = that.form.password;
          } else {
            data.msgcode = that.form.msgcode;
          }
          that.$http
            .postApi("/login", data)
            .then((res) => {
              if (res.data.code === 200) {
                that.Tlogin(res.data.data.sign, res.data.data.tengcloudid);
                localStorage.setItem("nickname", res.data.data.nickname);
                localStorage.setItem("member_id", res.data.data.member_id);
                localStorage.setItem("token", res.data.data.token);
                localStorage.setItem("phone", res.data.data.phone);
                if (that.currIndex === 2 && res.data.data.is_first === 2) {
                  that.sendPass();
                } else {
                  that.$message.success("登录成功");
                  that.$router.push("/homePage");
                }
              } else if (res.data.code === 200013) {
                that.wranText = "该账号不存在";
                that.dialogVisable = true;
              } else if (res.data.code === 200004) {
                that.wranText = "账号或密码错误";
                that.dialogVisable = true;
              } else if (res.data.code === 200009) {
                that.wranText = "验证码错误";
                that.dialogVisable = true;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        that.wranText = "请勾选《用户协议》及《隐私政策》";
        that.dialogVisable = true;
      }
    },
    sendPass() {
      const that = this;
      const data = {
        phone: that.form.account,
      };
      that.$http
        .postApi("/sendPass", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.$message.success("登录成功");
            that.$router.push("/homePage");
          }
        })
        .catch((err) => {
          that.logout();
          console.log(err);
        });
    },
    logout() {
      const that = this;
      that.$http
        .postApi("/logout", { member_id: localStorage.getItem("member_id") })
        .then((res) => {
          if (res.data.code === 200) {
            that.$store.commit("saveavatar", "");
            localStorage.clear();
            this.$store.dispatch("logout");
            location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Tlogin(sign, userid) {
      this.loading = true;
      this.tim
        .login({
          userID: userid,
          userSig: sign,
        })
        .then(() => {
          this.loading = false;
          this.$store.commit("toggleIsLogin", true);
          this.$store.commit("startComputeCurrent");
          // this.$store.commit('showMessage', { type: 'success', message: '登录成功' })
          this.$store.commit({
            type: "GET_USER_INFO",
            userID: userid,
            userSig: sign,
            sdkAppID: "1400496244",
          });
          localStorage.setItem(
            "sign",
            JSON.stringify({ userID: userid, userSig: sign })
          );
          // this.getTel();
          // this.$store.commit('showMessage', {
          //   type: 'success',
          //   message: '登录成功'
          // })
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("showMessage", {
            message: "登录失败：" + error.message,
            type: "error",
          });
          this.$router.push("/login");
        });
    },
    sendcode() {
      const that = this;
      let text =
        /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0123456789]|18[0123456789]|14[57])[0-9]{8}$/g;
      if (text.test(that.form.account)) {
        if (that.code === "发送验证码" || that.code === "重新发送") {
          const data = {
            phone: that.form.account,
          };
          that.$http
            .postApi("/sendCode", data)
            .then((res) => {
              if (res.data.code === 200) {
                // that.$message.success('发送成功！')
                let time = 60;
                let timer = setInterval(() => {
                  if (time > 0) {
                    time--;
                    that.code = "倒计时" + time + "s";
                  } else {
                    that.code = "重新发送";
                    clearInterval(timer);
                  }
                }, 600);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        that.$message.error("请输入正确的手机号！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  .header {
    width: 100%;
    background: #ffffff;
    height: 96px;
  }
  .head {
    height: 96px;
    background: #ffffff;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    div {
      width: 2px;
      height: 40px;
      background: #e7eaed;
      margin: 0 24px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: #101b28;
    }
    img {
      width: 72px;
      cursor: pointer;
    }
  }
  .main {
    position: absolute;
    // top: 96px;
    margin-top: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .mainbox {
      display: flex;
      .mleft {
        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }
        p {
          font-size: 16px;
          font-weight: 700;
          color: #525b66;
          line-height: 24px;
          margin: 16px 0 8px;
        }
      }
      .mright {
        width: 600px;
        height: 504px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 8px rgba(151, 165, 183, 0.1);
        .title {
          display: flex;
          div {
            width: 50%;
            height: 56px;
            border: 1px solid #f5f5f6;
            text-align: center;
            line-height: 56px;
            cursor: pointer;
            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
            span {
              font-size: 14px;
              font-weight: 500;
              color: #525b66;
              line-height: 24px;
              margin-left: 8px;
            }
          }
          div.active {
            border-bottom: 3px solid #3d7eff;
            height: 54px;
          }
        }
        .form {
          padding: 0 135px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: calc(100% - 58px);
          h3 {
            font-size: 18px;
            font-weight: 500;
            color: #101b28;
          }
          p {
            font-size: 14px;
            font-weight: 500;
            color: #97a5b7;
            margin-bottom: 36px;
            line-height: 24px;
          }
          .wraning {
            height: 32px;
            background: rgba(255, 134, 61, 0.1);
            border: 1px solid #ff863d;
            padding: 0 12px;
            color: #19293d;
            font-size: 12px;
            line-height: 32px;
            i {
              color: #ff863d;
              font-size: 24px;
              vertical-align: middle;
            }
          }
          .el-input,
          .el-button,
          .el-checkbox {
            margin-top: 18px;
          }
          .el-button {
            border-radius: 0;
          }
          .el-input /deep/input {
            border-radius: 0;
            border: 1px solid #97a5b7;
          }
          .el-checkbox /deep/ .el-checkbox__label {
            font-weight: 500;
            color: #525b66;
            line-height: 24px;
          }
          .blue {
            color: #3d7eff;
          }
          .code {
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }
  .foot {
    position: absolute;
    bottom: 0;
    padding: 30px 0;
    text-align: center;
    width: 100%;
    p {
      font-weight: 700;
      color: #97a5b7;
      line-height: 18px;
      a {
        color: #97a5b7;
      }
      span {
        background-color: #97a5b7;
        width: 1px;
        height: 14px;
        display: inline-block;
        margin: 0 32px;
      }
    }
    p + p {
      margin-top: 32px;
    }
  }
  .bg {
    width: 100%;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
