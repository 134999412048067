<template>
  <div class="homePage">
    <div class="banner content">
      <el-carousel arrow="never">
        <el-carousel-item
          v-for="(item, i) in advert.filter((x, i) => i < 7)"
          :key="i"
          @click.native="topages(item.url)"
        >
          <img :src="item.banner" alt="" />
          <h3>{{ item.title }}</h3>
        </el-carousel-item>
      </el-carousel>
      <div class="info">
        <div class="userinfo">
          <el-avatar
            :src="$store.getters.getavatar"
            v-if="$store.getters.getavatar && $store.getters.getavatar !== ''"
          ></el-avatar>
          <h4 v-if="username !== '' && username">{{ username }}</h4>
          <h4 @click="toPage('/login')" style="cursor: pointer" v-else>请登录</h4>
        </div>
        <div class="operation">
          <ul>
            <li @click="toPage('/shoppingCartItem')">
              <img src="../assets/img/Iconly_Bold_Buy@2x.png" alt="" />
              <p v-if="$store.getters.getnum">购物车({{ $store.getters.getnum }})</p>
              <p v-else>购物车</p>
            </li>
            <li @click="toPage('/myOrders')">
              <img src="../assets/img/Iconly_Bold_Paper@2x.png" alt="" />
              <p>我的订单</p>
            </li>
            <li @click="toPage('/myFavorites')">
              <img src="../assets/img/Iconly_Bold_Star@2x.png" alt="" />
              <p v-if="$store.getters.getnum">我的收藏</p>
              <p v-else>我的收藏</p>
            </li>
            <li @click="toim">
              <img src="../assets/img/Iconly_Bold_Notification@2x.png" alt="" />
              <p>我的消息</p>
            </li>
          </ul>
        </div>
        <div class="activity">
          <div class="title">
            <img src="../assets/img/Iconly_Bulk_Bookmark@2x.png" alt="" />
            <h3 @click="toPage('/activity', newsFrame)">福利活动</h3>
          </div>
          <ul>
            <li
              @click="toPage('/actProfile', item.frame_id, item.code)"
              v-for="item in actList"
              :key="item.code"
            >
              <p>活动</p>
              <p>{{ item.title }}</p>
            </li>
          </ul>
          <div class="customized">
            <img src="../assets/img/Iconly_Bulk_Edit@2x.png" alt="" />
            <span @click="toPage('/customized')">定制设计</span>
          </div>
        </div>
      </div>
    </div>
    <goods :goodTypes="goodTypes" :recGoods="recGoods" :photo_advert="photo_advert" />
  </div>
</template>
<script>
import goods from "./goods.vue";
export default {
  components: {
    goods,
  },
  data() {
    return {
      actList: [],
      goodTypes: [],
      recGoods: [],
      advert: [],
      photo_advert: [],
      newsFrame: 0,
      bz_frame_id: 0,
      enables: false
    };
  },
  computed: {
    username() {
      return localStorage.getItem("nickname");
    },
    sid() {
      return JSON.parse(localStorage.getItem("sid"));
    },
  },
  methods: {
    handleclose(){
      this.enables = false
    },
    toim() {
      this.$parent.toim();
    },
    toPage(page, id, item) {
      this.$router.push({ path: page, query: { id: id, item: item } });
      if (page === "/customized") {
        let routeData = this.$router.resolve({
          path: page,
          query: { id: id, item: item },
        });
        window.open(routeData.href, "_blank");
      }
    },
    topages(url) {
      if(url && url !== ''){
        window.open(url);
      }
    },
    getData() {
      const that = this;
      that.$http
        .postApi("/index", {})
        .then((res) => {
          if (res.data.code === 200) {
            console.log(res)
            that.advert = res.data.data.advert;
            that.photo_advert = res.data.data.photo_advert;
            that.actList = res.data.data.news;
            that.goodTypes = res.data.data.goods_type;
            that.recGoods = res.data.data.goods;
            that.newsFrame = res.data.data.news_frame;
            that.bz_frame_id = res.data.data.bz_frame_id;
            that.tel = res.data.data.officer_tel;
            that.$store.commit("savetel", res.data.data.officer_tel);
            that.$emit("bz_frame_id", that.bz_frame_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: center;
}
.homePage {
  padding-top: 32px;
  .banner {
    .el-carousel {
      max-width: 891px;
      height: 558px;
      width: 76%;
      .el-carousel__item {
        height: 558px;
        // width: 100%;
        img {
          width: 100%;
          height: 558px;
        }
        .itemBar {
          position: absolute;
          // top: 0;
          bottom: 110px;
          // width: 100%;
          // height: 100%;
          padding: 64px;
          p,
          h3,
          h5 {
            font-weight: 700;
            color: #ffffff;
            margin-bottom: 8px;
          }
          p {
            font-size: 20px;
          }
          h5 {
            font-size: 16px;
          }
          .btnBox {
            margin-top: 48px;
            .el-button {
              width: 144px;
              height: 40px;
              border-radius: 2px;
              box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
            }
            .el-button--default {
              background: transparent;
              color: #fff;
            }
            .el-button + .el-button {
              margin-left: 40px;
            }
          }
        }
        h3 {
          position: absolute;
          bottom: 0;
          font-size: 20px;
          background-color: rgba($color: #000000, $alpha: 0.3);
          text-align: center;
          font-weight: 700;
          color: #ffffff;
          width: 100%;
          line-height: 83px;
          height: 83px;
        }
      }
    }
    .info {
      position: relative;
      max-width: 288px;
      height: 526px;
      background: #ffffff;
      margin-left: 16px;
      width: 30%;
      padding: 16px;
      .userinfo {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .el-avatar {
          width: 48px;
          height: 48px;
          /deep/img {
            width: 100%;
            // height: auto;
          }
        }
        h4 {
          font-size: 16px;
          font-weight: 700;
          color: #101b28;
          margin-left: 16px;
          width: 94px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .operation {
        ul {
          margin-top: 42px;
          display: flex;
          flex-wrap: wrap;
          li {
            text-align: center;
            width: 50%;
            margin-bottom: 40px;
            cursor: pointer;
            img {
              width: 32px;
              height: 32px;
            }
            p {
              font-size: 12px;
              font-weight: 700;
              color: #101b28;
              line-height: 18px;
            }
          }
        }
      }
      .activity {
        .title {
          display: flex;
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
          h3 {
            font-size: 18px;
            font-weight: 700;
            color: #101b28;
            line-height: 18px;
            cursor: pointer;
          }
        }
        li {
          display: flex;
          margin-top: 16px;
          cursor: pointer;
          p:first-child {
            text-align: center;
            line-height: 24px;
            width: 50px;
            height: 24px;
            background: rgba($color: #ee4545, $alpha: 0.2);
            font-size: 14px;
            font-weight: 700;
            color: #ee4545;
            margin-right: 14px;
          }
          p:nth-child(2) {
            max-width: 221px;
            font-size: 16px;
            color: #101b28;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .customized {
          width: 100%;
          height: 64px;
          box-shadow: 0px -1px 0px 0px #f5f5f6;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: 0;
          left: 0;
          img {
            width: 32px;
            height: 32px;
            margin-right: 27px;
          }
          span {
            font-size: 16px;
            font-weight: 700;
            color: #101b28;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
