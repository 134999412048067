<template>
  <div class="goodsBox content">
    <div class="goodsTitle">
      <h2>海量、丰富的商品</h2>
      <p @click="toAllpage(0)">查看全部商品<i class="el-icon-arrow-right"></i></p>
    </div>
    <div
      class="goodsContent"
      v-for="(item, index) in goodTypes"
      :key="item.type_id"
    >
      <div class="contentTop">
        <p @click="toAllpage(item.type_id)">{{ item.type_name }}</p>
        <span>精选商品一键直达</span>
        <el-tabs
          v-model="activeName[index]"
          @tab-click="handleClick(activeName[index], index)"
          v-if="item.second.length > 0"
        >
          <el-tab-pane
            :label="s.type_name"
            :name="String(s.type_id)"
            v-for="s in item.second"
            :key="s.type_id"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="contentCenter">
        <div class="c-left">
          <img :src="typetitle[index].img" alt="" />
          <div class="c-text">
            <h3 v-if="index !== 3">
              {{ typetitle[index].title1 }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                typetitle[index].title2
              }}
            </h3>
            <h3 v-else>
              {{ typetitle[index].title1 }}{{ typetitle[index].title2 }}
            </h3>
            <p>{{ typetitle[index].content1 }}</p>
            <p>{{ typetitle[index].content2 }}</p>
          </div>
          <el-button @click="toAllpage(item.type_id)">查看更多</el-button>
        </div>
        <div class="c-right">
          <ul>
            <li
              @click="toPgae('/detail', g.goods_id)"
              v-for="g in item.goods"
              :key="g.goods_id"
            >
              <div class="liTop">
                <img
                  :src="g.cover_photo"
                  alt=""
                  v-if="
                    g.cover_photo !== '' &&
                    g.cover_photo.substr(
                      g.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) !== 'mp4'
                  "
                />
                <video
                  :src="g.cover_photo"
                  id="videos"
                  v-else-if="
                    g.cover_photo !== '' &&
                    g.cover_photo.substr(
                      g.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) === 'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="liContent">
                <h3>{{ g.goods_name }}</h3>
                <div class="price">
                  <div>
                    <p class="org" v-if="g.is_lease === 1">
                      <img
                        src="../assets/img/Food Icons _ Hot Icon@2x.png"
                        alt=""
                      />
                      租赁￥{{ g.lease_price }}起
                    </p>
                  </div>
                  <p v-if="g.is_sell === 1">
                    售价<span>￥{{ g.sell_price }}</span
                    >起
                  </p>
                </div>
                <div class="attach">
                  <span
                    v-for="(a, index) in g.service"
                    :key="index"
                    ><template v-if="index < 2">{{ a.service_name }}</template></span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="margin: 19px 0 40px" class="carouselBox">
      <el-carousel indicator-position="none" v-if="photo_advert">
        <el-carousel-item v-for="(item, index) in photo_advert" :key="index">
          <img :src="item.banner" alt="" />
        </el-carousel-item>
      </el-carousel>
      <img
        v-else
        src="../assets/img/Frame 90@2x.png"
        alt=""
        style="width: 100%"
      />
    </div>
    <div class="goodsContent" style="paddingbottom: 62px">
      <div class="contentTop">
        <p>推荐商品</p>
        <span>精选商品一键直达</span>
      </div>
      <div class="contentCenter">
        <div class="c-right" style="max-height: 772px; width: 100%">
          <ul style="margin-right: -30px">
            <li
              v-for="item in recGoods"
              :key="item.goods_id"
              style="margin-bottom: 28px; margin-right: 28px"
              @click="toPgae('/detail', item.goods_id)"
            >
              <div class="liTop">
                <img
                  :src="item.cover_photo"
                  alt=""
                  v-if="
                    item.cover_photo !== '' &&
                    item.cover_photo.substr(
                      item.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) !== 'mp4'
                  "
                />
                <video
                  :src="item.cover_photo"
                  id="videos"
                  v-else-if="
                    item.cover_photo !== '' &&
                    item.cover_photo.substr(
                      item.cover_photo.lastIndexOf('.') + 1,
                      3
                    ) === 'mp4'
                  "
                  preload
                ></video>
              </div>
              <div class="liContent">
                <h3>{{ item.goods_name }}</h3>
                <div class="price">
                  <div>
                    <p class="org" v-if="item.is_lease === 1">
                      <img
                        src="../assets/img/Food Icons _ Hot Icon@2x.png"
                        alt=""
                      />
                      租赁￥{{ item.lease_price }}起
                    </p>
                  </div>
                  <p v-if="item.is_sell === 1">
                    售价<span>￥{{ item.sell_price }}</span
                    >起
                  </p>
                </div>
                <div class="attach">
                  <span
                    v-for="(a, index) in item.service"
                    :key="index"
                    ><template v-if="index < 2">{{ a.service_name }}</template></span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['goodTypes', 'recGoods', 'photo_advert'],
  data () {
    return {
      activeName: [],
      typelist: [],
      typetitle: [{
        img: require('../assets/img/Group 30@2x.png'),
        title1: '一站式租赁',
        title2: '会展变轻松',
        content1: '欢迎来到共享展具平台',
        content2: '海量货源 精选展具'
      }, {
        img: require('../assets/img/Group 30_3@2x.png'),
        title1: '展具共享',
        title2: '服务到位',
        content1: '选择共享展具省时省力',
        content2: '送货准时达 安装专业高效'
      }, {
        img: require('../assets/img/Group 30_1@2x.png'),
        title1: '模块化展具',
        title2: '绿色环保',
        content1: '使用共享展具费用低',
        content2: '“0”押金“0”负担'
      }, {
        img: require('../assets/img/Group 30_2@2x.png'),
        title1: '一站式供应链',
        title2: '平台更省心',
        content1: '租售 设计 配送 安装',
        content2: '服务一票到底'
      }]
    }
  },
  methods: {
    handleClick (tab, event) {
      const that = this
      const data = {
        second_type_id: tab
      }
      that.$http.postApi('/get_second_goods', data).then(res => {
        if (res.data.code === 200) {
          // that.$set(that.goodTypes, event, res.data.data)
          that.goodTypes[event].goods = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toAllpage (id) {
      this.$bus.$emit('getData', id)
      this.$router.push('/goodslist')
    },
    toPgae (page, item) {
      let routeData = this.$router.resolve({ path: page, query: { item: item } });
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    this.$bus.$on('getData', id => {
      this.$bus.sendData = id
    })
  },
  watch: {
    goodTypes (val) {
      if (val.length > 0) {
        val.forEach((e, i) => {
          if (e.second.length > 0) {
            this.activeName[i] = String(e.second[0].type_id)
          }
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.goodsBox {
  .goodsTitle {
    margin-top: 56px;
    text-align: center;
    padding-bottom: 41px;
    h2 {
      font-size: 28px;
      font-weight: 500;
      color: #101b28;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 700;
      color: #3d7eff;
      cursor: pointer;
    }
  }
  .goodsContent {
    padding-bottom: 39px;
    .contentTop {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 700;
        color: #101b28;
        margin-right: 27px;
      }
      span {
        font-size: 12px;
        color: #525b66;
      }
      .el-tabs {
        margin-left: auto;
        /deep/.el-tabs__nav-wrap::after {
          display: none;
        }
        /deep/.el-tabs__active-bar {
          border-radius: 2px;
        }
      }
    }
    .contentCenter {
      display: flex;
      .c-left {
        width: 263px;
        min-width: 263px;
        height: 756px;
        margin-right: 16px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .c-text,
        .el-button {
          position: absolute;
        }
        .c-text {
          top: 102px;
          left: 36px;
          color: #ffffff;
          h3 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 19px;
          }
          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .el-button {
          bottom: 16px;
          right: 16px;
          border-radius: 2px;
        }
      }
      .c-right {
        width: 100%;
        max-height: 772px;
        overflow: hidden;
        ul {
          display: flex;
          flex-wrap: wrap;
          margin-right: -16px;
          // justify-content: space-between;
          li {
            margin-right: 16px;
            width: 216px;
            min-height: 368px;
            background-color: #fff;
            margin-bottom: 16px;
            border: 1px solid #fff;
            // transition: height 1s;
            .liTop {
              height: 216px;
              overflow: hidden;
              video,
              img {
                width: 100%;
                height: 100%;
                // max-height: 155px;
              }
            }
            .liContent {
              padding: 16px;
              h3 {
                font-size: 16px;
                font-weight: 700;
                color: #101b28;
                height: 44px;
                margin-bottom: 8px;
                max-width: 185px;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
              .attach {
                span {
                  font-size: 12px;
                  color: #525b66;
                }
                span + span {
                  margin-left: 17px;
                }
              }
              .price {
                margin-bottom: 4px;
                height: 43px;
                img {
                  width: 17px;
                  height: 17px;
                  margin-right: 4px;
                }
                div {
                  margin-bottom: 5px;
                  height: 19px;
                }
                p {
                  font-size: 12px;
                  color: #3c5169;
                  display: flex;
                  align-items: center;
                  span {
                    font-size: 14px;
                    font-weight: 700;
                    color: #19293d;
                  }
                }
                p.org {
                  margin-left: 0;
                  font-size: 14px;
                  font-weight: 700;
                  color: #ff863d;
                }
              }
            }
            &:hover {
              border: 1px solid #3d7eff;
            }
          }
          &::after {
            content: '';
            width: 217px;
          }
        }
      }
    }
  }
}
.carouselBox {
  width: 100%;
  /deep/.el-carousel__container {
    height: 194px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>