<template>
  <div class="myFavorites">
    <ul v-if="list.length > 0">
      <li
        v-for="g in list"
        :key="g.goods_id"
        @click.stop="toPgae('/detail', g.goods_id)"
      >
        <div class="liTop">
          <img
            :src="g.cover_photo"
            alt=""
            v-if="
              g.cover_photo !== '' &&
              g.cover_photo.substr(g.cover_photo.lastIndexOf('.') + 1, 3) !==
                'mp4'
            "
          />
          <video
            :src="g.cover_photo"
            id="videos"
            v-else-if="
              g.cover_photo !== '' &&
              g.cover_photo.substr(g.cover_photo.lastIndexOf('.') + 1, 3) ===
                'mp4'
            "
            preload
          ></video>
          <img
            :src="close"
            alt=""
            class="close"
            @mousemove="closehover"
            @mouseleave="closeleave"
            @click.stop="collection(g.goods_id)"
          />
        </div>
        <div class="liContent">
          <h3>{{ g.goods_name }}</h3>
          <div class="price">
            <div>
              <p class="org" v-if="g.is_lease === 1">
                <img
                  src="../../assets/img/Food Icons _ Hot Icon@2x.png"
                  alt=""
                />
                租赁￥{{ g.lease_price }}起
              </p>
            </div>
            <p v-if="g.is_sell === 1">
              售价<span>￥{{ g.sell_price }}</span
              >起
            </p>
          </div>
          <div class="attach">
            <span
              v-for="(a, index) in g.service"
              :key="a.service_name"
              ><template v-if="index < 2">{{ a.service_name }}</template></span
            >
          </div>
        </div>
      </li>
    </ul>
    <div class="empty" v-else>
      <img src="../../assets/img/Frame 785.png" alt="" />
      <h4>您还没有收藏的商品</h4>
      <p>去商品中心逛逛吧～</p>
      <el-button type="primary" @click="$router.push('/goodslist')"
        >去逛逛</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      close: require('../../assets/img/Group33.png'),
      list: []
    }
  },
  methods: {
    closehover () {
      this.close = require('../../assets/img/Group33_1.png')
    },
    closeleave () {
      this.close = require('../../assets/img/Group33.png')
    },
    toPgae (page, item) {
      let routeData = this.$router.resolve({ path: page, query: { item: item } });
      window.open(routeData.href, '_blank');
    },
    collection (id) {
      const that = this
      const data = {
        goods_id: id
      }
      if (localStorage.getItem('token')) {
        data.member_id = localStorage.getItem('member_id')
      }
      that.$http.postApi('/del_collection', data).then(res => {
        if (res.data.code === 200) {
          that.getdata()
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getdata () {
      const that = this
      const data = {
        page: 1,
        pagesize: 10,
        member_id: localStorage.getItem('member_id')
      }
      that.$http.postApi('/collection_list', data).then(res => {
        if (res.data.code === 200) {
          that.list = res.data.data.list
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getdata()
  },
}
</script>

<style lang="scss" scoped>
.myFavorites {
  // background-color: #fff;
  padding-top: 16px;
  overflow: hidden;
  ul {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-right: -23px;
    li {
      margin-right: 23px;
      width: 188px;
      height: 323px;
      background-color: #fff;
      margin-bottom: 16px;
      border: 1px solid #fff;
      // transition: height 1s;
      .liTop {
        height: 188px;
        position: relative;
        video,
        img {
          width: 100%;
          height: 100%;
          // max-height: 155px;
        }
        .close {
          position: absolute;
          width: 32px;
          height: 32px;
          right: 0;
          cursor: pointer;
          display: none;
        }
      }
      .liContent {
        padding: 16px;
        h3 {
          font-size: 16px;
          font-weight: 700;
          color: #101b28;
          height: 44px;
          margin-bottom: 8px;
          max-width: 185px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .attach {
          span {
            font-size: 12px;
            color: #525b66;
          }
          span + span {
            margin-left: 17px;
          }
        }
        .price {
          margin-bottom: 4px;
          height: 43px;
          div {
            height: 19px;
            margin-bottom: 5px;
          }
          img {
            width: 17px;
            height: 17px;
            margin-right: 4px;
          }
          p {
            font-size: 12px;
            color: #3c5169;
            display: flex;
            align-items: center;
            span {
              font-size: 14px;
              font-weight: 700;
              color: #19293d;
            }
          }
          p.org {
            margin-left: 0;
            font-size: 14px;
            font-weight: 700;
            color: #ff863d;
          }
        }
      }
      &:hover {
        border: 1px solid #3d7eff;
        .close {
          display: inline-block;
        }
      }
    }
    &::after {
      content: '';
      width: 217px;
    }
  }
  .empty {
    margin-top: 62px;
    text-align: center;
    h4 {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #525b66;
      line-height: 28px;
    }
    p {
      font-size: 14px;
      color: #97a5b7;
      line-height: 28px;
      margin-bottom: 24px;
    }
    .el-button {
      width: 144px;
      height: 40px;
      border-radius: 2px;
    }
  }
}
</style>