var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"myFavorites"},[(_vm.list.length > 0)?_c('ul',_vm._l((_vm.list),function(g){return _c('li',{key:g.goods_id,on:{"click":function($event){$event.stopPropagation();return _vm.toPgae('/detail', g.goods_id)}}},[_c('div',{staticClass:"liTop"},[(
            g.cover_photo !== '' &&
            g.cover_photo.substr(g.cover_photo.lastIndexOf('.') + 1, 3) !==
              'mp4'
          )?_c('img',{attrs:{"src":g.cover_photo,"alt":""}}):(
            g.cover_photo !== '' &&
            g.cover_photo.substr(g.cover_photo.lastIndexOf('.') + 1, 3) ===
              'mp4'
          )?_c('video',{attrs:{"src":g.cover_photo,"id":"videos","preload":""}}):_vm._e(),_c('img',{staticClass:"close",attrs:{"src":_vm.close,"alt":""},on:{"mousemove":_vm.closehover,"mouseleave":_vm.closeleave,"click":function($event){$event.stopPropagation();return _vm.collection(g.goods_id)}}})]),_c('div',{staticClass:"liContent"},[_c('h3',[_vm._v(_vm._s(g.goods_name))]),_c('div',{staticClass:"price"},[_c('div',[(g.is_lease === 1)?_c('p',{staticClass:"org"},[_c('img',{attrs:{"src":require("../../assets/img/Food Icons _ Hot Icon@2x.png"),"alt":""}}),_vm._v(" 租赁￥"+_vm._s(g.lease_price)+"起 ")]):_vm._e()]),(g.is_sell === 1)?_c('p',[_vm._v(" 售价"),_c('span',[_vm._v("￥"+_vm._s(g.sell_price))]),_vm._v("起 ")]):_vm._e()]),_c('div',{staticClass:"attach"},_vm._l((g.service),function(a,index){return _c('span',{key:a.service_name},[(index < 2)?[_vm._v(_vm._s(a.service_name))]:_vm._e()],2)}),0)])])}),0):_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("../../assets/img/Frame 785.png"),"alt":""}}),_c('h4',[_vm._v("您还没有收藏的商品")]),_c('p',[_vm._v("去商品中心逛逛吧～")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push('/goodslist')}}},[_vm._v("去逛逛")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }