<template>
  <div class="payment content">
    <div class="crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>收银台</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="checkStands">
      <i class="el-icon-success"></i>
      <div>
        <p>您的订单已提交成功！请尽快付款</p>
        <p>
          金额：<span>￥{{ pay.total_price }}</span
          ><el-button type="text" @click="getdetail">查看订单明细</el-button>
        </p>
      </div>
    </div>
    <div class="checkStand">
      <ul>
        <li>
          <p>用户名：</p>
          <div class="name">{{ nickname }}</div>
        </li>
        <li>
          <p>请选择支付方式：</p>
          <div>
            <div class="vent">
              <div
                :class="{
                  active: pay_type === 2,
                  disabled: pay.order_status === 4 && pay_type !== 2,
                }"
              >
                <el-radio
                  v-model="pay_type"
                  :label="2"
                  :disabled="pay.order_status === 4 && pay_type !== 2"
                  ><img src="../../assets/img/支付宝pc 1.png" alt=""
                /></el-radio>
              </div>
              <div
                :class="{
                  active: pay_type === 1,
                  disabled: pay.order_status === 4 && pay_type !== 1,
                }"
              >
                <el-radio
                  v-model="pay_type"
                  :label="1"
                  :disabled="pay.order_status === 4 && pay_type !== 1"
                  ><img src="../../assets/img/未标题-1_03 2.png" alt=""
                /></el-radio>
              </div>
              <div
                :class="{
                  active: pay_type === 3,
                  disabled: pay.order_status === 4 && pay_type !== 3,
                }"
              >
                <el-radio
                  v-model="pay_type"
                  :label="3"
                  :disabled="pay.order_status === 4 && pay_type !== 3"
                  ><img src="../../assets/img/Frame 737.png" alt=""
                /></el-radio>
              </div>
            </div>
            <div class="ventTips">
              <span><i class="el-icon-warning"></i></span>
              <p>
                温馨提示：选择支付宝、微信支付方式仅可以开具“增值税普通发票”，如果要开具“增值税专用发票”请使用公对公“银行转账”的方式支付。<br />如商品付款分为预付款+尾款，则预付款、尾款两次支付方式需保持一致。
              </p>
            </div>
            <div class="ventInfo" v-if="pay_type === 3">
              <div>
                <h5>请将资金转账至下面平台收款账户</h5>
                <p>
                  收款账号<span>{{ bank.company_account }}</span>
                </p>
                <p>
                  收款银行<span>{{ bank.company_bank }}</span>
                </p>
                <p>
                  收款户头<span>{{ bank.company_name }}</span>
                </p>
                <p>
                  开户行信息<span>{{ bank.company_zbank }}</span>
                </p>
              </div>
            </div>
          </div>
        </li>
        <li v-if="pay_type === 3">
          <ul>
            <li>
              <p>转账银行：</p>
              <div class="inputBox">
                <el-input
                  v-model="form.bank_name"
                  placeholder="请填写转账银行，如“中国银行”（必填）"
                ></el-input>
              </div>
            </li>
            <li>
              <p>银行卡号：</p>
              <div class="inputBox">
                <el-input
                  v-model="form.bank_number"
                  placeholder="请填写转账银行卡号（必填）"
                ></el-input>
              </div>
            </li>
            <li>
              <p>户头名称：</p>
              <div class="inputBox">
                <el-input
                  v-model="form.bank_account"
                  placeholder="请填写银行卡户头名称（必填）"
                ></el-input>
              </div>
            </li>
          </ul>
        </li>
        <li style="justify-content: flex-end; margin-right: 10%">
          <el-button type="primary" @click="getailplay">确认付款</el-button>
        </li>
      </ul>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      center
      width="25%"
      :before-close="handleClose"
    >
      <div class="diabox">
        <h3>请完成付款后，点击按钮确认订单是否支付成功</h3>
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div>
          <el-button type="primary" @click="submitForm">我已支付成功</el-button>
        </div>
        <div>
          <el-button type="text" @click="cancalForm">选择其他支付方式</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="payVisible"
      :show-close="false"
      width="25%"
      :before-close="handleClose"
    >
      <div class="paybox" v-if="payres && pay_type === 1">
        <h3><i class="el-icon-success"></i>支付成功</h3>
      </div>
      <div class="paybox" v-if="!payres && pay_type === 1">
        <h3><i class="el-icon-warning" style="color: #ff863d"></i>支付失败</h3>
      </div>
      <div class="paybox" v-if="pay_type === 3">
        <h3><i class="el-icon-success"></i>提交成功</h3>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$router.push('/')" v-if="payres || pay_type === 3"
          >返回首页</el-button
        >
        <el-button
          type="primary"
          v-if="payres || pay_type === 3"
          @click="$router.push('/myOrders')"
          >查看订单</el-button
        >
        <el-button
          type="primary"
          v-if="!payres && pay_type === 1"
          @click="payVisible = false"
          >订单重试</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="订单明细" :visible.sync="centerDialogVisible" width="25%" center>
      <div>
        <div class="orderdel" v-for="item in goods.lease" :key="item.infoid">
          <template v-if="goods.lease && goods.lease.length > 0">
            <p>{{ item.goods_name }}</p>
            <span>{{ item.specs_name }}x{{ item.car_num }}</span>
          </template>
        </div>
        <div class="orderdel" v-for="item in goods.sell" :key="item.infoid">
          <template v-if="goods.sell && goods.sell.length > 0">
            <p>{{ item.goods_name }}</p>
            <span>{{ item.specs_name }}x{{ item.car_num }}</span>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      pay_type: 2,
      centerDialogVisible: false,
      input: "",
      enVis: false,
      dialogVisible: false,
      payVisible: false,
      form: {
        bank_name: "",
        bank_number: "",
        bank_account: "",
      },
      bank: {},
      payres: null,
    };
  },
  methods: {
    getdetail() {
      this.centerDialogVisible = true;
    },
    cancalForm() {
      this.dialogVisible = false;
    },
    submitForm() {
      const that = this;
      if (that.pay_type === 3) {
        this.dialogVisible = false;
        this.payVisible = true;
      } else if (that.pay_type === 1) {
        const data = {
          order_sn: that.pay.orderNo,
          member_id: localStorage.getItem("member_id"),
          type: that.pay.type,
        };
        that.$http
          .postApi("/wx_returnback", data)
          .then((res) => {
            if (res.data.code === 200) {
              if (res.data.data) {
                that.payres = true;
              } else {
                that.payres = false;
              }
              this.dialogVisible = false;
              this.payVisible = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleClose() {},
    getbank() {
      const that = this;
      that.$http
        .postApi("/getaccount", {})
        .then((res) => {
          if (res.data.code === 200) {
            that.bank = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getailplay() {
      const that = this;
      const data = {
        order_sn: that.pay.orderNo,
        type: that.pay.type,
        pay_type: that.pay_type,
      };
      if (that.pay_type === 1) {
        data.wxtype = 1;
      } else if (that.pay_type === 3) {
        Object.assign(data, that.form);
      }
      that.$http
        .postApi("/pay", data)
        .then((res) => {
          if (res.data.code === 200) {
            this.dialogVisible = true;
            if (that.pay_type === 1) {
              this.$nextTick(() => {
                new QRCode(this.$refs.qrCodeUrl, {
                  text: res.data.data.code_url, // 需要转换为二维码的内容
                  width: 100,
                  height: 100,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
              });
            } else if (that.pay_type === 2) {
              let qqq = res.data.data;
              let div = document.createElement("div");
              div.innerHTML = qqq;
              document.body.appendChild(div);
              // document.forms[1].setAttribute('target', '_blank')
              document.forms["alipaysubmit"].submit();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    pay() {
      if (JSON.parse(sessionStorage.getItem("pay")).order_status === 4) {
        this.pay_type = JSON.parse(sessionStorage.getItem("pay")).pay_type;
      }
      return JSON.parse(sessionStorage.getItem("pay"));
    },
    nickname() {
      return localStorage.getItem("nickname");
    },
    goods() {
      return this.$store.getters.getgoods;
    },
  },
  mounted() {
    this.getbank();
  },
};
</script>

<style lang="scss" scoped>
.payment {
  padding-bottom: 74px;
  .crumb {
    padding: 26px 0;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .checkStands {
    margin: 10px 0 24px;
    display: flex;
    padding: 36px;
    align-items: center;
    background: #ffffff;
    i {
      font-size: 50px;
      color: #3d7eff;
      margin-right: 36px;
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: #19293d;
      span {
        color: #ff863d;
        font-size: 30px;
        font-weight: 700;
        margin-right: 12px;
      }
    }
    p + p {
      margin-top: 16px;
    }
  }
  .checkStand {
    padding-top: 34px;
    background-color: #fff;
    ul {
      padding-left: 58px;
      li {
        display: flex;
        padding-bottom: 32px;
        font-size: 20px;
        > p {
          width: 160px;
          font-weight: 700;
          color: #101b28;
          margin-right: 50px;
        }
        .name {
          font-weight: 500;
          color: #101b28;
        }
        .org {
          color: #ff863d;
          font-weight: 700;
        }
        .vent {
          display: flex;
          .disabled {
            opacity: 0.5;
          }
          div {
            width: 250px;
            height: 66px;
            border: 1px solid #e7eaed;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            .el-radio {
              display: flex;
              align-items: center;
            }
          }
          div + div {
            margin-left: 24px;
          }
          .active {
            border: 1px solid #3d7eff;
            box-shadow: 4px 4px 4px 0px rgba(61, 126, 255, 0.1);
          }
        }
        .ventTips {
          margin-top: 12px;
          padding: 8px;
          background: rgba(255, 134, 61, 0.1);
          border: 1px solid #ff863d;
          font-size: 12px;
          font-weight: 500;
          color: #101b28;
          display: flex;
          align-items: center;
          span {
            color: #ff863d;
            font-size: 24px;
            margin-right: 4px;
          }
        }
        .ventInfo {
          margin-top: 40px;
          max-width: 391px;
          h5 {
            font-weight: 500;
            padding-bottom: 20px;
            font-size: 16px;
            color: #19293d;
            border-bottom: 1px solid #f5f5f6;
            margin-bottom: 24px;
          }
          div {
            // width: 313px;
            // height: 116px;
            border: 1px solid #f5f5f6;
            border-radius: 9px;
            box-shadow: 4px 4px 5px 0px rgba(245, 245, 246, 0.6);
            padding: 24px;
            padding-right: 12px;
            p {
              font-size: 14px;
              font-weight: 400;
              color: #525b66;
              span {
                float: right;
                font-size: 14px;
                font-weight: 700;
                color: #19293d;
              }
            }
            p + p {
              margin-top: 29px;
            }
          }
        }
        .inputBox {
          /deep/.el-input__inner {
            width: 381px;
            height: 36px;
            border: 1px solid #97a5b7;
            border-radius: 3px;
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
          }
        }
        .el-button {
          width: 144px;
          height: 40px;
          border-radius: 2px;
        }
        ul {
          padding-left: 0;
          li {
            padding-bottom: 16px;
          }
        }
      }
    }
  }
  .orderdel {
    font-size: 14px;
    font-weight: 500;
    color: #101b28;
    line-height: 20px;
    p {
      margin-bottom: 8px;
    }
    span {
      color: #97a5b7;
    }
  }
  .orderdel + .orderdel {
    margin-top: 24px;
  }
}
.diabox {
  h3 {
    text-align: center;
    margin-bottom: 36px;
  }
  div {
    margin-top: 16px;
    text-align: center;
    .el-button--text {
      font-size: 14px;
      font-weight: 500;
      color: #525b66;
    }
  }
  .qrcode {
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
  }
}
.paybox {
  h3 {
    font-size: 26px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #101b28;
    i {
      font-size: 36px;
      color: #3d7eff;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
</style>
