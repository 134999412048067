var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goodsBox content"},[_c('div',{staticClass:"goodsTitle"},[_c('h2',[_vm._v("海量、丰富的商品")]),_c('p',{on:{"click":function($event){return _vm.toAllpage(0)}}},[_vm._v("查看全部商品"),_c('i',{staticClass:"el-icon-arrow-right"})])]),_vm._l((_vm.goodTypes),function(item,index){return _c('div',{key:item.type_id,staticClass:"goodsContent"},[_c('div',{staticClass:"contentTop"},[_c('p',{on:{"click":function($event){return _vm.toAllpage(item.type_id)}}},[_vm._v(_vm._s(item.type_name))]),_c('span',[_vm._v("精选商品一键直达")]),(item.second.length > 0)?_c('el-tabs',{on:{"tab-click":function($event){return _vm.handleClick(_vm.activeName[index], index)}},model:{value:(_vm.activeName[index]),callback:function ($$v) {_vm.$set(_vm.activeName, index, $$v)},expression:"activeName[index]"}},_vm._l((item.second),function(s){return _c('el-tab-pane',{key:s.type_id,attrs:{"label":s.type_name,"name":String(s.type_id)}})}),1):_vm._e()],1),_c('div',{staticClass:"contentCenter"},[_c('div',{staticClass:"c-left"},[_c('img',{attrs:{"src":_vm.typetitle[index].img,"alt":""}}),_c('div',{staticClass:"c-text"},[(index !== 3)?_c('h3',[_vm._v(" "+_vm._s(_vm.typetitle[index].title1)+"    "+_vm._s(_vm.typetitle[index].title2)+" ")]):_c('h3',[_vm._v(" "+_vm._s(_vm.typetitle[index].title1)+_vm._s(_vm.typetitle[index].title2)+" ")]),_c('p',[_vm._v(_vm._s(_vm.typetitle[index].content1))]),_c('p',[_vm._v(_vm._s(_vm.typetitle[index].content2))])]),_c('el-button',{on:{"click":function($event){return _vm.toAllpage(item.type_id)}}},[_vm._v("查看更多")])],1),_c('div',{staticClass:"c-right"},[_c('ul',_vm._l((item.goods),function(g){return _c('li',{key:g.goods_id,on:{"click":function($event){return _vm.toPgae('/detail', g.goods_id)}}},[_c('div',{staticClass:"liTop"},[(
                  g.cover_photo !== '' &&
                  g.cover_photo.substr(
                    g.cover_photo.lastIndexOf('.') + 1,
                    3
                  ) !== 'mp4'
                )?_c('img',{attrs:{"src":g.cover_photo,"alt":""}}):(
                  g.cover_photo !== '' &&
                  g.cover_photo.substr(
                    g.cover_photo.lastIndexOf('.') + 1,
                    3
                  ) === 'mp4'
                )?_c('video',{attrs:{"src":g.cover_photo,"id":"videos","preload":""}}):_vm._e()]),_c('div',{staticClass:"liContent"},[_c('h3',[_vm._v(_vm._s(g.goods_name))]),_c('div',{staticClass:"price"},[_c('div',[(g.is_lease === 1)?_c('p',{staticClass:"org"},[_c('img',{attrs:{"src":require("../assets/img/Food Icons _ Hot Icon@2x.png"),"alt":""}}),_vm._v(" 租赁￥"+_vm._s(g.lease_price)+"起 ")]):_vm._e()]),(g.is_sell === 1)?_c('p',[_vm._v(" 售价"),_c('span',[_vm._v("￥"+_vm._s(g.sell_price))]),_vm._v("起 ")]):_vm._e()]),_c('div',{staticClass:"attach"},_vm._l((g.service),function(a,index){return _c('span',{key:index},[(index < 2)?[_vm._v(_vm._s(a.service_name))]:_vm._e()],2)}),0)])])}),0)])])])}),_c('div',{staticClass:"carouselBox",staticStyle:{"margin":"19px 0 40px"}},[(_vm.photo_advert)?_c('el-carousel',{attrs:{"indicator-position":"none"}},_vm._l((_vm.photo_advert),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{attrs:{"src":item.banner,"alt":""}})])}),1):_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/img/Frame 90@2x.png"),"alt":""}})],1),_c('div',{staticClass:"goodsContent",staticStyle:{"paddingbottom":"62px"}},[_vm._m(0),_c('div',{staticClass:"contentCenter"},[_c('div',{staticClass:"c-right",staticStyle:{"max-height":"772px","width":"100%"}},[_c('ul',{staticStyle:{"margin-right":"-30px"}},_vm._l((_vm.recGoods),function(item){return _c('li',{key:item.goods_id,staticStyle:{"margin-bottom":"28px","margin-right":"28px"},on:{"click":function($event){return _vm.toPgae('/detail', item.goods_id)}}},[_c('div',{staticClass:"liTop"},[(
                  item.cover_photo !== '' &&
                  item.cover_photo.substr(
                    item.cover_photo.lastIndexOf('.') + 1,
                    3
                  ) !== 'mp4'
                )?_c('img',{attrs:{"src":item.cover_photo,"alt":""}}):(
                  item.cover_photo !== '' &&
                  item.cover_photo.substr(
                    item.cover_photo.lastIndexOf('.') + 1,
                    3
                  ) === 'mp4'
                )?_c('video',{attrs:{"src":item.cover_photo,"id":"videos","preload":""}}):_vm._e()]),_c('div',{staticClass:"liContent"},[_c('h3',[_vm._v(_vm._s(item.goods_name))]),_c('div',{staticClass:"price"},[_c('div',[(item.is_lease === 1)?_c('p',{staticClass:"org"},[_c('img',{attrs:{"src":require("../assets/img/Food Icons _ Hot Icon@2x.png"),"alt":""}}),_vm._v(" 租赁￥"+_vm._s(item.lease_price)+"起 ")]):_vm._e()]),(item.is_sell === 1)?_c('p',[_vm._v(" 售价"),_c('span',[_vm._v("￥"+_vm._s(item.sell_price))]),_vm._v("起 ")]):_vm._e()]),_c('div',{staticClass:"attach"},_vm._l((item.service),function(a,index){return _c('span',{key:index},[(index < 2)?[_vm._v(_vm._s(a.service_name))]:_vm._e()],2)}),0)])])}),0)])])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentTop"},[_c('p',[_vm._v("推荐商品")]),_c('span',[_vm._v("精选商品一键直达")])])}]

export { render, staticRenderFns }