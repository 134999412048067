<template>
  <div class="agree content">
    <div class="crumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="text">
      <h3>{{title}}</h3>
      <div v-html="agree"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agree: '',
      title: ''
    }
  },
  methods: {
    getpolicy () {
      var that = this
      that.$http.postApi('/getprivacy_exhibition', {}).then(res => { // 隐私政策
        if (res.data.code === 200) {
          that.agree = res.data.data.privacy
          that.title = res.data.data.privacy_title
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getagree () {
      var that = this
      that.$http.postApi('/get_agreement_exhibition', {}).then(res => {
        if (res.data.code === 200) {
          that.agree = res.data.data.agreement
          that.title = res.data.data.agreement_title
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  watch: {
    '$route.path': {
      handler (val, oldval) {
        if (val === '/pc_policy') {
          this.getpolicy()
        } else if (val === '/pc_agree') {
          this.getagree()
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.crumb {
  padding: 26px 0;
  /deep/.el-breadcrumb__inner.is-link {
    color: #3d7eff;
  }
  /deep/.el-breadcrumb__inner {
    font-size: 20px;
    font-weight: 700;
    color: #101b28;
  }
}
.agree {
  padding-bottom: 82px;
  background-color: #f5f5f6;
}
.text {
  background-color: #fff;
  padding: 46px 17.3%;
  min-height: calc(100vh - 379px);
  h3{
    text-align: center;
    font-size: 20px;
    margin-bottom: 46px;
  }
}
</style>