<template>
  <div class="design content">
    <div class="suspension">
      <h3>
        {{ list.title }}
      </h3>
      <p>
        {{ list.contents }}
      </p>
    </div>
    <div class="nosuspension">
      <h3>
        <img src="../../assets/img/Component 21.png" alt="" />请填写您的定制需求
      </h3>
      <div>
        <el-form
          ref="form"
          :model="list"
          label-position="top"
          style="margin-left: 32px"
        >
          <el-form-item
            :label="item.fieldname"
            v-for="(item, index) in list.params"
            :key="index"
            :prop="`params[${index}].fieldvalue`"
            :rules="{
              required: true,
              message: '请填写完整信息',
              trigger: item.fieldtype === 1?'blur': 'change',
            }"
          >
            <el-input
              v-model="item.fieldvalue"
              v-if="item.fieldtype === 1"
              :placeholder="'请填写' + item.fieldname"
            ></el-input>
            <el-date-picker
              type="date"
              v-if="item.fieldtype === 2"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              v-model="item.fieldvalue"
            ></el-date-picker>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button type="primary" @click="onSubmit('form')"
              >立即创建</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog
      :visible.sync="payVisible"
      :show-close="false"
      width="25%"
      :before-close="handleClose"
    >
      <div class="paybox">
        <h3><i class="el-icon-success"></i>提交成功</h3>
        <p>服务人员会在24h内以电话形式联系您， 请保持手机畅通</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      list: {},
      payVisible: false
    }
  },
  methods: {
    handleClose () {
      this.$router.push('/')
      // this.payVisible = false
      // this.getDetail()
    },
    getDetail () {
      const that = this
      const data = {
        infoid: that.$route.query.id
      }
      that.$http.postApi('/pcNeedTemplateDetail', data).then(res => {
        if (res.data.code === 200) {
          that.list = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onSubmit (formName) {
      const that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            params: that.list.params,
            tid: that.$route.query.id,
            phone: localStorage.getItem('phone')
          }
          that.$http.postApi('/pcNeedTemplateSave', data).then(res => {
            if (res.data.code === 200) {
              that.payVisible = true
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    }
  },
  mounted () {
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
.design {
  position: relative;
  .suspension {
    position: absolute;
    top: -56px;
    padding: 42px;
    padding-left: 70px;
    width: 1088px;
    // width: calc(100% - 35.5%);
    border-top: 4px solid #3d7eff;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px rgba(151, 165, 183, 0.1);
    widows: calc(100% - 84px);
    background-image: url('../../assets/img/user.png');
    background-position: right bottom;
    background-repeat: no-repeat;
    h3 {
      font-size: 17px;
      font-weight: 500;
      color: #101b28;
      position: relative;
      &::before {
        content: '';
        display: block;
        background-image: url('../../assets/img/定制 (1)@2x.png');
        width: 20px;
        height: 20px;
        background-size: 100%;
        position: absolute;
        left: -28px;
        top: 4px;
      }
    }
    p {
      margin-top: 11px;
      font-size: 13px;
      font-weight: 500;
      color: #525b66;
    }
  }
  .nosuspension {
    padding-top: 211px;
    h3 {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #101b28;
      img {
        width: 24px;
        height: 24px;
        vertical-align: text-top;
      }
    }
    /deep/.el-form-item__label {
      font-size: 15px;
      font-weight: 700;
      padding: 0;
      color: #101b28;
    }
    /deep/.el-textarea__inner,
    /deep/.el-input__inner {
      border: 1px solid #e7eaed;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      color: #101b28;
    }
    /deep/.el-textarea__inner:focus,
    /deep/.el-input__inner:focus {
      border-color: #3d7eff;
      box-shadow: 2px 2px 4px 0px rgba(61, 126, 255, 0.2);
    }
    .el-button {
      width: 186px;
      height: 54px;
      border-radius: 2px;
    }
  }
}
.paybox {
  h3 {
    font-size: 26px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #101b28;
    i {
      font-size: 36px;
      color: #3d7eff;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  p {
    text-align: center;
    margin: 0 auto;
    max-width: 249px;
  }
}
/deep/.el-dialog__body {
  padding-bottom: 18px;
}
</style>