<template>
  <div class="ensure">
    <div class="content">
      <div class="crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>我的购物车</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="makelist">
        <div class="mTabs">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <!-- <div style="min-width: 144px"></div> -->
          <div style="min-width: 202px">商品</div>
          <ul>
            <!-- <li>商品</li> -->
            <li style="width: 96px">规格</li>
            <li>单价</li>
            <li style="width: 96px">数量</li>
            <li>金额</li>
          </ul>
        </div>
        <div class="lease" v-if="list.lease && list.lease.length > 0">
          <div class="lease_order">
            <span>租赁订单</span>
          </div>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in list.lease" :label="item" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    :src="item.cover_photo"
                    @click="topage(item.goods_id)"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    @click="topage(item.goods_id)"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name" @click="topage(item.goods_id)">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>
                        ￥{{ item.price }}/<span v-if="item.lease_type === 1">日</span
                        ><span v-if="item.lease_type === 2">次</span>
                      </p>
                      <!-- <p>x1</p> -->
                      <p style="width: 96px" @click.stop.prevent>
                        <el-input-number
                          v-model="item.car_num"
                          @change="handleChange($event, item.infoid)"
                          :min="1"
                          :max="10"
                          size="mini"
                          label="描述文字"
                        ></el-input-number>
                      </p>
                      <p style="text-align: center">
                        ￥{{ item.price * item.car_num }}<br />
                        <el-button type="text" @click="delrow(item.infoid)"
                          >删除</el-button
                        >
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div class="divflex" v-for="(items, i) in item.service" :key="i">
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p style="width: 56px; padding: 0 20px">x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="state">
                  <div class="w_202">运费</div>
                  <p class="autoleft">￥60</p>
                </div> -->
                </div>
              </div>
              <div class="payType">
                <p>
                  付款方式：<span v-if="item.pay_type === 1">一次性付全款</span
                  ><span v-if="item.pay_type === 2"
                    >预付款{{ item.percent }}%+尾款{{ 100 - item.percent }}%</span
                  >
                </p>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="lease" v-if="list.sell && list.sell.length > 0">
          <div class="lease_order">
            <span>购买订单</span>
          </div>
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in list.sell" :label="item" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    @click="topage(item.goods_id)"
                    :src="item.cover_photo"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    @click="topage(item.goods_id)"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name" @click="topage(item.goods_id)">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>￥{{ item.price }}</p>
                      <p style="width: 96px" @click.stop.prevent>
                        <el-input-number
                          v-model="item.car_num"
                          @change="handleChange($event, item.infoid)"
                          :min="1"
                          :max="10"
                          size="mini"
                          label="描述文字"
                        ></el-input-number>
                      </p>
                      <p style="text-align: center">
                        ￥{{ Math.floor(item.price * item.car_num * 100) / 100 }}<br />
                        <el-button type="text" @click="delrow(item.infoid)"
                          >删除</el-button
                        >
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div class="divflex" v-for="(items, i) in item.service" :key="i">
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p style="width: 56px; padding: 0 20px">x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="state">
                  <div class="w_202">运费</div>
                  <p class="autoleft">￥60</p>
                </div> -->
                </div>
              </div>
              <div class="payType">
                <p>
                  付款方式：<span v-if="item.pay_type === 1">一次性付全款</span
                  ><span v-if="item.pay_type === 2"
                    >预付款{{ item.percent }}%+尾款{{ 100 - item.percent }}%</span
                  >
                </p>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="lease is_failure" v-if="list.abnormal && list.abnormal.length > 0">
          <div class="failure">失效</div>
          <ul>
            <li v-for="item in list.abnormal" :label="item.goods_id" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    :src="item.cover_photo"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>￥{{ item.price }}</p>
                      <p style="width: 56px; padding: 0 20px">x{{ item.car_num }}</p>
                      <p style="text-align: center">
                        ￥{{ item.price * item.car_num }}<br />
                        <el-button type="text" @click="delrow(item.infoid)"
                          >删除</el-button
                        >
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div class="divflex" v-for="(items, i) in item.service" :key="i">
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p style="width: 56px; padding: 0 20px">x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="state">
                  <div class="w_202">运费</div>
                  <p class="autoleft">￥60</p>
                </div> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="empty"
          v-if="
            list.lease &&
            list.sell &&
            list.abnormal &&
            list.lease.length === 0 &&
            list.sell.length === 0 &&
            list.abnormal.length === 0
          "
        >
          <img src="../../assets/img/Frame 785.png" alt="" />
          <h4>购物车空空如也</h4>
          <p>去商品中心逛逛吧～</p>
          <el-button type="primary" @click="$router.push('/goodslist')">去逛逛</el-button>
        </div>
      </div>
      <div class="settlement">
        <el-button type="text" style="color: #f56c6c" @click="alldel">批量删除</el-button>
        <p style="margin-left: auto">
          已选商品<span>{{ checkedCities.length }}</span
          >件
        </p>
        <p>
          合计： <span>￥{{ total }}</span>
        </p>
        <el-button type="primary" @click="paycenter">结算</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="448px"
      class="print"
      top="10%"
      :close-on-click-modal="false"
      :show-close="false"
      :before-close="handleCloses"
    >
      <h3 slot="title">请阅读以下信息</h3>
      <div>
        <p>下单即表示同意以下信息：</p>
        <p v-for="item in inform" :key="item" v-html="item"></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloses">不同意</el-button>
        <el-button type="primary" @click="settlement()">同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      isIndeterminate: false,
      list: [],
      total: 0,
      dialogVisible: false,
      count: 0,
      inform: null
    };
  },
  methods: {
    getNote() {
      const that = this
      that.$http.postApi('/order_information', {}).then(res => {
        if(res.data.code === 200) {
          that.inform = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handleCloses() {
      this.dialogVisible = false;
    },
    topage(id) {
      let routeData = this.$router.resolve({ path: "/detail", query: { item: id } });
      window.open(routeData.href, "_blank");
    },
    paycenter() {
      const that = this;
      if (localStorage.getItem("member_id")) {
          that.count > 0 ? that.dialogVisible = true : that.settlement()
      } else {
        that.$router.push("/login");
      }
    },
    settlement() {
      const that = this;
      if (that.checkedCities.length > 0) {
        let arr = [];
        that.checkedCities.forEach((ele) => {
          arr.push(ele.infoid);
        });
        let carids = arr.join(",");
        const data = {
          member_id: localStorage.getItem("member_id"),
          carids: carids,
        };
        that.$http
          .postApi("/cargoodslist", data)
          .then((res) => {
            if (res.data.code === 200) {
              that.$store.commit("savegoods", res.data.data.list);
              let form = {
                infoid: carids,
                mitval: "",
                cityval: "",
                areaval: "",
                timeer: "",
                daynum: 1,
              };
              that.$store.commit("saveform", form);
              that.$router.push("/order");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleChange(val, id) {
      const that = this;
      const data = {
        carids: id,
        num: val,
      };
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that.$http
        .postApi("/carsedit", data)
        .then((res) => {
          if (res.data.code === 200) {
            // that.getData()
            let total = 0;
            let order = 0;
            this.checkedCities.forEach((ele) => {
              ele.service.forEach((el) => {
                order += Math.floor(Number(el.service_price) * 100);
              });
            });
            this.checkedCities.forEach((ele) => {
              total += Math.floor(Number(ele.price) * Number(ele.car_num) * 100);
            });
            this.total = (total + order) / 100;
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    handleCheckAllChange(val) {
      let list = [...this.list.lease, ...this.list.sell];
      this.count = val ? this.list.lease.length : 0
      this.checkedCities = val ? list : [];
      this.isIndeterminate = false;
      let total = 0;
      let order = 0;
      this.checkedCities.forEach((ele) => {
        ele.service.forEach((el) => {
          order += Number(el.service_price);
        });
      });
      this.checkedCities.forEach((ele) => {
        total += ele.price * ele.car_num;
      });
      this.total = total + order;
    },
    handleCheckedCitiesChange(value) {
      this.count = 0
      let list = [...this.list.lease, ...this.list.sell];
      let checkedCount = value.length;
      this.checkAll = checkedCount === list.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < list.length;
      this.total = 0;
      let order = 0;
      let total = 0;
      value.forEach((ele) => {
        ele.service.forEach((el) => {
          order += Math.floor(Number(el.service_price) * 100);
        });
      });
      value.forEach((ele) => {
        if(this.list.lease.find(x => x === ele)) {
          this.count ++
        } else {
          this.count --
        }
        total += Math.floor(Number(ele.price) * Number(ele.car_num) * 100);
        // this.total += ele.price * ele.car_num + order
      });
      this.total = Math.floor(total + order) / 100;
    },
    alldel() {
      if (this.checkedCities.length > 0) {
        let list = [];
        this.checkedCities.forEach((ele) => {
          list.push(ele.infoid);
        });
        let str = list.join(",");
        this.delrow(str);
      }
    },
    delrow(id) {
      const that = this;
      const data = {
        carids: String(id),
      };
      that.$http
        .postApi("/carsdel", data)
        .then((res) => {
          if (res.data.code === 200) {
            String(id)
              .split(",")
              .forEach((ele) => {
                this.checkedCities.splice(
                  this.checkedCities.findIndex((x) => x.infoid === Number(ele)),
                  1
                );
                this.handleCheckedCitiesChange(this.checkedCities);
              });
            that.getData();
          }
        })
        .catch((err) => {
          that.$message.error("删除失败");
          console.log(err);
        });
    },
    getData() {
      const that = this;
      const loading = that.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const data = {
        member_id: localStorage.getItem("member_id"),
        page: 1,
        limit: 100,
      };
      that.$http
        .postApi("/carslist", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.list = res.data.data.list;
            loading.close();
          }
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
    this.getNote()
  },
};
</script>

<style lang="scss" scoped>
.ensure {
  margin-top: 46px;
  background-color: #fff;
  .crumb {
    margin-bottom: 26px;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
  }
  .makelist {
    // .mTabs {
    //   height: 54px;
    //   border: 1px solid #e7eaed;
    //   margin-bottom: 12px;
    //   display: flex;
    //   padding: 0 41px;
    //   padding-left: 149px;
    //   align-items: center;

    // }
    min-height: 243px;
    .mTabs {
      height: 54px;
      border: 1px solid #e7eaed;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      padding: 0 26px;
      /deep/.el-checkbox__label {
        font-size: 16px;
        color: #000;
        line-height: 21px;
        margin-right: 86px;
      }
      /deep/.el-checkbox__inner {
        width: 16px;
        height: 16px;
      }
      ul {
        flex: 1 auto;
        display: flex;
        width: 100%;
        justify-content: space-between;
        li {
          line-height: 56px;
        }
      }
    }
    .lease {
      border: 1px solid #e7eaed;
      margin-bottom: 12px;
      .lease_order {
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 53px;
        border-bottom: 1px solid #f5f5f6;
        /deep/.el-checkbox__inner {
          width: 16px;
          height: 16px;
        }
        span {
          font-size: 18px;
          color: #101b28;
        }
      }
      .el-checkbox-group,
      ul {
        padding: 0 40px;
        .el-checkbox,
        li {
          display: flex;
          white-space: normal;
          margin-top: 16px;
          width: 100%;
          border-bottom: 1px solid #f5f5f6;
          /deep/.el-checkbox__label {
            width: 98%;
          }
          /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
            color: #101b28;
          }
          .detailed {
            display: flex;
            width: 100%;
            video,
            img {
              width: 88px;
              height: 88px;
              margin-right: 16px;
            }
            .angeforderte {
              width: 100%;
              .state {
                display: flex;
                margin-bottom: 17px;
                .w_202 {
                  width: 202px;
                }
                .w_96 {
                  width: 96px;
                }
                .w_90 {
                  width: 90px;
                  text-align: center;
                  line-height: 26px;
                }
                .divflex {
                  display: flex;
                  justify-content: space-between;
                  p {
                    .el-input-number--mini {
                      width: 96px;
                      /deep/.el-input-number__decrease,
                      /deep/.el-input-number__increase {
                        line-height: 26px;
                      }
                    }
                  }
                }
                div {
                  font-size: 14px;
                  font-weight: 500;
                  color: #101b28;
                  line-height: 20px;
                }
                .gury {
                  color: #97a5b7;
                }
                .goods_name {
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  width: 192px;
                  padding-right: 10px;
                }
              }
            }
          }
          .payType {
            height: 56px;
            background: #f5f5f6;
            display: flex;
            align-items: center;
            padding-left: 26px;
            padding-right: 6px;
            margin-bottom: 16px;
            p {
              font-size: 14px;
              color: #101b28;
            }
          }
        }
      }
    }
    .is_failure {
      background-color: #f5f5f6;
      display: flex;
      align-items: center;
      padding-left: 6px;
      .failure {
        width: 20px;
        height: 14px;
        background: #e7eaed;
        font-size: 10px;
        color: #525b66;
      }
      ul {
        padding-left: 12px;
        width: calc(100% - 72px);
        .state div {
          color: #97a5b7 !important;
        }
        .detailed img {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
  }
  .settlement {
    height: 64px;
    border: 1px solid #f5f5f6;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    margin: 66px 0 6px;
    padding: 0 6px;
    .el-button--primary {
      width: 142px;
      height: 52px;
      border-radius: 2px;
    }
    p {
      margin-right: 24px;
      span {
        font-weight: 700;
        font-size: 15px;
        color: #ff863d;
      }
    }
  }
}
.empty {
  margin-top: 80px;
  margin-bottom: 35.5vh;
  text-align: center;
  h4 {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #525b66;
    line-height: 28px;
  }
  p {
    font-size: 14px;
    color: #97a5b7;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .el-button {
    width: 144px;
    height: 40px;
    border-radius: 2px;
  }
}
.print {
  /deep/.el-dialog__header {
    height: 64px;
    padding: 0;
  }
  /deep/.el-dialog__body {
    padding: 18px 36px;
  }
  h3 {
    font-size: 17px;
    font-weight: 500;
    color: #101b28;
    line-height: 64px;
    text-align: center;
  }
  div {
    height: 300px;
    overflow-y: auto;
    p {
      font-size: 14px;
      color: #101b28;
      line-height: 16px;
    }
    p + p {
      margin-top: 22px;
    }
  }
  div::-webkit-scrollbar-thumb {
    width: 3px;
  }
  div::-webkit-scrollbar-button {
    display: none;
    width: 0;
  }
  div::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/
    // background: #007acc;
    border-radius: 3px;
  }
  /*定义滚动条轨道（凹槽）样式*/
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* 较少使用 */
    border-radius: 3px;
  }
  /*定义滑块 样式*/
  div::-webkit-scrollbar-thumb {
    border-radius: 3px;
    height: 70px; /* 滚动条滑块长度 */
    background-color: #ccc;
  }

  .el-button {
    border-radius: 3px;
  }
}
</style>
