<template>
  <div class="ensure">
    <div class="content">
      <div class="crumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="curror" @click.native="$router.go(-1)"
            >返回</el-breadcrumb-item
          >
          <el-breadcrumb-item>确认订单</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="make">
        <el-form
          ref="form"
          :model="form"
          label-width="100px"
          :rules="rules"
          label-position="left"
        >
          <el-form-item label="送达时间" prop="use_starttime">
            <!-- <el-date-picker
            type="date"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            v-model="form.use_starttime"
            style="width: 100%"
          ></el-date-picker> -->
            <el-date-picker
              v-model="form.use_starttime"
              :picker-options="pickerOptions"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期时间"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="使用天数"
            prop="use_days"
            v-if="goods.lease && goods.lease.length > 0"
          >
            <el-input-number
              v-model="form.use_days"
              @change="handleChange"
              :min="1"
              :max="15"
              label="描述文字"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="商品运输" prop="deliver_type">
            <el-select
              v-model="form.deliver_type"
              placeholder="请选择配送方式"
              @change="handlewater"
            >
              <el-option label="商家送货" value="0"></el-option>
              <el-option label="自提" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="收货地址"
            class="address"
            prop="area_id"
            v-if="form.deliver_type == 0"
          >
            <el-select
              v-model="mitval"
              placeholder="请选择"
              @change="checkarea"
              value-key="code"
            >
              <el-option
                v-for="item in mitArea"
                :key="item.code"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="cityval"
              placeholder="请选择"
              @change="checkarea"
              value-key="code"
            >
              <el-option
                v-for="item in city"
                :key="item.code"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="areaval"
              placeholder="请选择"
              @change="checkarea"
              value-key="code"
            >
              <el-option
                v-for="item in Areas"
                :key="item.code"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="form.user_address"
              placeholder="请填写详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="收货人姓名" prop="user_name">
            <el-input
              v-model="form.user_name"
              placeholder="请填写收货人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="user_phone">
            <el-input
              v-model="form.user_phone"
              placeholder="请填写收货人联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注要求" prop="order_remark">
            <el-input type="textarea" v-model="form.order_remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="makelist">
        <div class="mTabs">
          <div style="min-width: 202px">商品</div>
          <ul>
            <!-- <li>商品</li> -->
            <li style="width: 96px">规格</li>
            <li>单价</li>
            <li>数量</li>
            <li>金额</li>
          </ul>
        </div>
        <div class="lease" v-if="goods.lease && goods.lease.length > 0">
          <div class="lease_order">
            <img src="../../assets/img/Frame 534@2x.png" alt="" />
            <span>租赁订单</span>
          </div>
          <ul>
            <li v-for="item in goods.lease" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    :src="item.cover_photo"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>￥{{ item.price }}</p>
                      <p>x{{ item.car_num }}</p>
                      <p v-if="item.lease_type === 1">
                        ￥{{
                          Math.floor(
                            item.price * item.car_num * form.use_days * 100
                          ) / 100
                        }}
                      </p>
                      <p v-if="item.lease_type === 2">
                        ￥{{
                          Math.floor(item.price * item.car_num * 100) / 100
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div
                        class="divflex"
                        v-for="(items, i) in item.service"
                        :key="i"
                      >
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p>x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">运费</div>
                    <p class="autoleft">￥{{ item.express_price }}</p>
                  </div>
                </div>
              </div>
              <div class="payType">
                <p v-if="item.pay_type === 1">付款方式：一次性付全款</p>
                <p v-if="item.pay_type === 2">
                  付款方式：预付款{{ item.percent }}%+尾款{{
                    100 - item.percent
                  }}%
                </p>
                <p class="autoleft">
                  合计<span>￥{{ summoney(item, 'lease') }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="lease" v-if="goods.sell && goods.sell.length > 0">
          <div class="lease_order">
            <img src="../../assets/img/Frame 534_1@2x.png" alt="" />
            <span>购买</span>
          </div>
          <ul>
            <li v-for="item in goods.sell" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    :src="item.cover_photo"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>￥{{ item.price }}</p>
                      <p>x{{ item.car_num }}</p>
                      <p>
                        ￥{{
                          Math.floor(item.price * item.car_num * 100) / 100
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div
                        class="divflex"
                        v-for="(items, i) in item.service"
                        :key="i"
                      >
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p>x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">运费</div>
                    <p class="autoleft">￥{{ item.express_price }}</p>
                  </div>
                </div>
              </div>
              <div class="payType">
                <p v-if="item.pay_type === 1">付款方式：一次性付全款</p>
                <p v-if="item.pay_type === 2">
                  付款方式：预付款{{ item.percent }}%+尾款{{
                    100 - item.percent
                  }}%
                </p>
                <p class="autoleft">
                  合计<span>￥{{ summoney(item, 'sell') }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="lease is_failure"
          v-if="goods.abnormal && goods.abnormal.length > 0"
        >
          <div class="failure">失效</div>
          <ul>
            <li v-for="item in goods.abnormal" :key="item.infoid">
              <div class="detailed">
                <div>
                  <img
                    :src="item.cover_photo"
                    alt=""
                    v-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) !== 'mp4'
                    "
                  />
                  <video
                    :src="item.cover_photo"
                    id="videos"
                    v-else-if="
                      item.cover_photo !== '' &&
                      item.cover_photo.substr(
                        item.cover_photo.lastIndexOf('.') + 1,
                        3
                      ) === 'mp4'
                    "
                    preload
                  ></video>
                </div>
                <div class="angeforderte">
                  <div class="state">
                    <div class="goods_name">
                      {{ item.goods_name }}
                    </div>
                    <div class="divflex" style="flex: 1 auto">
                      <p class="w_96 gury">{{ item.specs_name }}</p>
                      <p>￥{{ item.price }}</p>
                      <p>x{{ item.car_num }}</p>
                      <p>
                        ￥{{
                          Math.floor(item.price * item.car_num * 100) / 100
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">附加服务</div>
                    <div style="flex: 1 auto">
                      <div
                        class="divflex"
                        v-for="(items, i) in item.service"
                        :key="i"
                      >
                        <p class="gury w_96">{{ items.service_name }}</p>
                        <p>￥{{ items.service_price }}</p>
                        <p>x1</p>
                        <p>￥{{ items.service_price }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="state">
                    <div class="w_202">运费</div>
                    <p class="autoleft">￥{{ item.express_price }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="all_price">
        <p>
          商品总金额(含服务费)<span>￥{{ total }}</span>
        </p>
        <p>
          运费金额<span>￥{{ goods.express_price }}</span>
        </p>
        <p>
          合计总金额<span
            >￥{{
              Math.floor((total + Number(goods.express_price)) * 100) / 100
            }}</span
          >
        </p>
        <p>
          预付款金额<span>￥{{ charge }}</span>
        </p>
        <p>
          尾款金额<span
            >￥{{
              Math.floor(
                (total + Number(goods.express_price)) * 100 - charge * 100
              ) / 100
            }}</span
          >
        </p>
      </div>
      <div class="settlement">
        <p>商品总金额(含服务费) ￥{{ total }}</p>
        <p>
          尾款金额 ￥{{
            Math.floor(
              (total + Number(goods.express_price)) * 100 - charge * 100
            ) / 100
          }}
        </p>
        <p>
          预付款金额 <span>￥{{ charge }}</span>
        </p>
        <el-button type="primary" @click="submit('form')">提交订单</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择配送范围'));
      } else if (this.form.user_address === '') {
        callback(new Error('请填写详细地址'));
      } else {
        callback();
      }
    };
    return {
      form: {
        use_starttime: '',
        use_days: 1,
        deliver_type: '0',
        user_address: '',
        user_name: '',
        user_phone: '',
        order_remark: '',
        source: 1,
        area_id: ''
      },
      rules: {
        use_starttime: [
          { required: true, message: '请选择送达时间', trigger: 'change' }
        ],
        use_days: [
          { required: true, message: '请选择使用天数', trigger: 'change' }
        ],
        area_id: [
          { required: true, validator: validatePass, trigger: 'click' }
        ],
        deliver_type: [
          { required: true, message: '请选择运输方式', trigger: 'change' }
        ],
        user_name: [
          { required: true, message: '请填写收货人姓名', trigger: 'blur' },
        ],
        user_phone: [
          { required: true, message: '请填写收货人电话', trigger: 'blur' },
          { pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0-9]|18[0-9]|14[57])[0-9]{8}$/, message: '请输入正确的手机号', trigger: 'click' }
        ],
        order_remark: [
          { required: true, message: '请填写备注信息', trigger: 'blur' },
        ],
      },
      mitArea: [],
      Areas: [],
      city: [],
      mitval: '',
      cityval: '',
      areaval: '',
      pickerOptions: {
        disabledDate (time) {
          // return time.getTime() < Date.now() - 8.64e7;   //禁用以前的日期，今天不禁用
          return time.getTime() < Date.now();    //禁用今天以及以前的日期
        }
      },
      goods: {}
    }
  },
  methods: {
    handleChange () {

    },
    submit (formName) {
      const that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {}
          if (that.formdata.infoid) {
            data = {
              ...that.form,
              member_id: localStorage.getItem('member_id'),
              carids: that.formdata.infoid,
              express_price: that.goods.express_price
            }
          } else if (that.formdata.goods_id) {
            let arr = []
            that.formdata.goods_serid.forEach(ele => {
              arr.push(ele.service_id)
            })
            let goods_serid = arr.join(',')
            data = {
              member_id: localStorage.getItem('member_id'),
              goods_sid: that.formdata.goods_sid[0].specs_id,
              goods_serid: goods_serid,
              goods_id: that.formdata.goods_id,
              source: 1,
              deliver_type: that.form.deliver_type,
              use_starttime: that.form.use_starttime,
              express_price: that.goods.express_price,
              use_days: that.form.use_days,
              order_remark: that.form.order_remark,
              user_address: that.form.user_address,
              user_name: that.form.user_name,
              area_id: that.form.area_id,
              user_phone: that.form.user_phone,
              type: that.formdata.type,
              car_num: that.formdata.num
            }
          }
          if (this.goods.lease.length > 0 || this.goods.sell.length > 0) {
            that.$http.postApi('/createorder', data).then(res => {
              if (res.data.code === 200) {
                res.data.data.type = 1
                sessionStorage.setItem('pay', JSON.stringify(res.data.data))
                that.$store.commit('savegoods', that.goods)
                that.$router.push('/payment')
              }
            }).catch(err => {
              console.log(err)
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    summoney (item, type) {
      let order = 0
      item.service.forEach(ele => {
        order += Number(ele.service_price)
      })
      let par = item.percent / 100
      if (type === 'lease') {
        if (item.lease_type === 1) {
          return Math.floor((item.price * item.car_num * this.form.use_days + order + item.express_price) * 100 * par) / 100
        } else if (item.lease_type === 2) {
          return Math.floor((item.price * item.car_num + order + item.express_price) * 100 * par) / 100
        }
      } else if (type === 'sell') {
        return Math.floor((item.price * item.car_num + order + item.express_price) * 100 * par) / 100
      }
    },
    handlewater (val) {
      if (val == 1) {
        this.checkarea({ code: '', type: 3 })
      }
    },
    getdata () {
      this.mitval = this.formdata.mitval
      this.form.use_starttime = this.formdata.timeer
      this.form.use_days = this.formdata.daynum
      this.goods = this.$store.getters.getgoods
      let p1 = new Promise((resolve, reject) => {
        this.getArea(this.mitval.code, this.mitval.type)
        if (resolve) {
          setTimeout(() => {
            this.cityval = this.formdata.cityval
          }, 300)
        }
      })
      let p2 = new Promise((resolve, reject) => {
        this.getArea(this.formdata.cityval.code, this.formdata.cityval.type)
        if (resolve) {
          setTimeout(() => {
            this.areaval = this.formdata.areaval
            this.form.area_id = this.areaval.code
          }, 500)
        }
      })

      // let p3 = Promise.reject('失败')

      Promise.all([p1, p2]).then((result) => {
        // console.log(result)               //['成功了', 'success']
      }).catch((error) => {
        // console.log(error)
      })
      // this.getArea(this.mitval.code, this.mitval.type)
      // this.getArea(this.cityval.code, this.cityval.type)
      // this.getArea(this.areaval.code, this.areaval.type)
    },
    getArea (code, type) {
      const that = this
      const data = {
        area_code: code || 0,
        type: type || 0
      }
      that.$http.postApi('/getbusarea', data).then(res => {
        if (res.data.code === 200) {
          if (type === 1) {
            that.cityval = ''
            that.areaval = ''
            that.city = res.data.data
          } else if (type === 2) {
            that.areaval = ''
            that.Areas = res.data.data
          } else {
            that.mitArea = res.data.data
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    checkarea (val) {
      const that = this
      if (val.type !== 3) {
        this.getArea(val.code, val.type)
      } else {
        that.form.area_id = val.code
        let data = {}
        if (that.formdata.infoid) {
          data = {
            member_id: localStorage.getItem('member_id'),
            carids: that.formdata.infoid,
            areaid: that.form.area_id,
          }
        } else if (that.formdata.goods_id) {
          let arr = []
          that.formdata.goods_serid.forEach(ele => {
            arr.push(ele.service_id)
          })
          let goods_serid = arr.join(',')
          data = {
            member_id: localStorage.getItem('member_id'),
            goods_sid: that.formdata.goods_sid[0].specs_id,
            goods_serid: goods_serid,
            car_num: that.formdata.num,
            areaid: that.form.area_id,
            goods_id: that.formdata.goods_id,
            type: that.formdata.type
          }
        }
        that.$http.postApi('/cargoodslist', data).then(res => {
          if (res.data.code === 200) {
            that.goods = res.data.data.list
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
  },
  computed: {
    total () {
      let sell = 0
      let lease = 0
      if (this.goods.sell) {
        this.goods.sell.forEach(ele => {
          let order = 0
          ele.service.forEach(el => {
            order += Number(el.service_price)
          })
          sell += Math.floor((Number(ele.price) * ele.car_num + order) * 100)
        })
      }
      if (this.goods.lease) {
        this.goods.lease.forEach(ele => {
          let order = 0
          ele.service.forEach(el => {
            order += Number(el.service_price)
          })
          if (ele.lease_type === 1) {
            lease += Math.floor((Number(ele.price) * ele.car_num * this.form.use_days + order) * 100)
          } else if (ele.lease_type === 2) {
            lease += Math.floor((Number(ele.price) * ele.car_num + order) * 100)
          }
        })
      }
      return (sell + lease) / 100
    },
    charge () {
      let sell = 0
      let lease = 0
      if (this.goods.sell) {
        this.goods.sell.forEach(ele => {
          let order = 0
          ele.service.forEach(el => {
            order += Number(el.service_price)
          })
          let par = ele.percent / 100
          sell += Math.floor((Number(ele.price) * ele.car_num + order + Number(ele.express_price)) * 100 * par)
        })
      }
      if (this.goods.lease) {
        this.goods.lease.forEach(ele => {
          let order = 0
          ele.service.forEach(el => {
            order += Number(el.service_price)
          })
          let par = ele.percent / 100
          if (ele.lease_type === 1) {
            lease += Math.floor((Number(ele.price) * ele.car_num * this.form.use_days + order + Number(ele.express_price)) * 100 * par)
          } else if (ele.lease_type === 2) {
            lease += Math.floor((Number(ele.price) * ele.car_num + order + Number(ele.express_price)) * 100 * par)
          }
        })
      }
      return (sell + lease) / 100
    },
    formdata () {
      return this.$store.getters.getform
    }
  },
  mounted () {
    this.getdata()
    this.getArea()
  }
}
</script>

<style lang="scss" scoped>
.ensure {
  margin-top: 26px;
  background-color: #fff;
  .crumb {
    margin-bottom: 26px;
    /deep/.el-breadcrumb__inner.is-link {
      color: #3d7eff;
    }
    /deep/.el-breadcrumb__inner {
      font-size: 20px;
      font-weight: 700;
      color: #101b28;
    }
    .curror {
      /deep/.el-breadcrumb__inner {
        cursor: pointer;
        color: #3d7eff !important;
      }
    }
  }
  .make {
    padding-left: 30px;
    width: 50%;
    .address {
      .el-select {
        width: 33%;
        margin-bottom: 8px;
      }
    }
  }
  .makelist {
    .mTabs {
      height: 54px;
      border: 1px solid #e7eaed;
      margin-bottom: 12px;
      display: flex;
      padding: 0 41px;
      padding-left: 149px;
      align-items: center;
      ul {
        flex: 1 auto;
        display: flex;
        width: 100%;
        justify-content: space-between;
        li {
          line-height: 56px;
        }
      }
    }
    .lease {
      border: 1px solid #e7eaed;
      margin-bottom: 12px;
      .lease_order {
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 53px;
        background: linear-gradient(
          180deg,
          rgba(61, 126, 255, 0.1),
          rgba(61, 126, 255, 0) 100%
        );
        border-bottom: 1px solid #f5f5f6;
        img {
          width: 18px;
          height: 18px;
        }
        span {
          font-size: 18px;
          margin-left: 8px;
          color: #101b28;
        }
      }
      ul {
        padding: 0 40px;
        li {
          margin-top: 16px;
          border-bottom: 1px solid #f5f5f6;
          .detailed {
            display: flex;
            video,
            img {
              width: 88px;
              height: 88px;
              margin-right: 16px;
            }
            .angeforderte {
              width: 100%;
              .state {
                display: flex;
                // justify-content: space-between;
                margin-bottom: 17px;
                .w_202 {
                  width: 202px;
                }
                .w_96 {
                  width: 96px;
                }
                .divflex {
                  display: flex;
                  justify-content: space-between;
                }
                div {
                  font-size: 14px;
                  font-weight: 500;
                  color: #101b28;
                  line-height: 20px;
                }
                .gury {
                  color: #97a5b7;
                  font-weight: 400;
                }
                .goods_name {
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  height: 40px;
                  width: 192px;
                  padding-right: 10px;
                }
              }
            }
          }
          .payType {
            height: 56px;
            background: #f5f5f6;
            display: flex;
            align-items: center;
            padding-left: 26px;
            padding-right: 6px;
            margin-bottom: 16px;
            p {
              font-size: 14px;
              color: #101b28;
              span {
                color: #ff863d;
                font-weight: 700;
              }
            }
          }
          .autoleft {
            margin-left: auto;
          }
        }
      }
    }
    .is_failure {
      background-color: #f5f5f6;
      display: flex;
      align-items: center;
      padding-left: 6px;
      .failure {
        width: 20px;
        height: 14px;
        background: #e7eaed;
        font-size: 10px;
        color: #525b66;
      }
      ul {
        padding-left: 12px;
        width: calc(100% - 72px);
        .state div {
          color: #97a5b7 !important;
        }
        .detailed img {
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
          -ms-filter: grayscale(100%);
          -o-filter: grayscale(100%);
          filter: grayscale(100%);
          filter: grayscale(100%);
        }
      }
    }
  }
  .all_price {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    p {
      font-size: 14px;
      font-weight: 500;
      color: #101b28;
      margin-right: 32px;
      span {
        color: #ff863d;
        margin-left: 16px;
      }
    }
  }
  .settlement {
    height: 64px;
    border: 1px solid #f5f5f6;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 12px 0 6px;
    padding: 0 6px;
    .el-button {
      width: 142px;
      height: 52px;
      border-radius: 2px;
    }
    p {
      margin-right: 24px;
      span {
        font-weight: 700;
        font-size: 15px;
        color: #ff863d;
      }
    }
  }
}
</style>