<template>
  <div class="myProfile">
    <el-form ref="form" :model="form" label-width="100px" label-position="left">
      <el-form-item label="当前头像">
        <div class="myavator">
          <img v-if="form.pic !== ''" :src="form.pic" alt="" />
          <img v-else src="../../assets/img/circle.png" alt="" />
          <p @click="uploadImg">更换</p>
        </div>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input v-model="form.nickname"></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="form.phone" :disabled="!codevis"></el-input>
        <el-button type="text" class="codes" @click="modify" v-if="!codevis"
          >更改</el-button
        >
      </el-form-item>
      <el-form-item label="验证码" v-if="codevis">
        <el-input v-model="form.code"></el-input>
        <el-button type="text" class="code" @click="sendcode">{{
          code
        }}</el-button>
        <el-button type="primary" plain class="codes" @click="modifyenter"
          >确认</el-button
        >
      </el-form-item>
      <el-form-item label="单位名称">
        <el-input v-model="form.work_unit"></el-input>
      </el-form-item>
      <el-form-item label="账号创建时间">
        <div class="danger">
          <p>{{ myprofile.create_time }}</p>
          <el-button type="danger" plain @click="cancellation"
            >注销账号</el-button
          >
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" class="submit"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
    <div class="saves">保存成功</div>
    <input
      type="file"
      id="imagePicker"
      ref="imagePicker"
      accept=".jpg, .jpeg, .png, .gif, .bmp"
      @change="sendImage"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        nickname: "",
        pic: "",
        code: "",
        work_unit: "",
      },
      code: "发送验证码",
      codevis: false,
    };
  },
  methods: {
    modify() {
      this.codevis = true;
    },
    modifyenter() {
      const that = this;
      if (that.form.code === "") {
        this.codevis = false;
        return false;
      }
      const data = {
        phone: that.form.phone,
        msgcode: that.form.code,
        member_id: localStorage.getItem("member_id"),
      };
      that.$http
        .postApi("/edit_member", data)
        .then((res) => {
          if (res.data.code === 200) {
            document.getElementsByClassName("saves")[0].style.opacity = 0.5;
            setTimeout(() => {
              document.getElementsByClassName("saves")[0].style.opacity = 0;
            }, 2000);
            this.codevis = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadImg() {
      this.$refs.imagePicker.click();
    },
    // 修改头像
    sendImage(e) {
      var that = this;
      var formData = new FormData();
      formData.append("file", e.target.files[0]);
      console.log("e.target.files[0]", e.target.files[0].type);
      if (
        e.target.files[0].type == "image/jpeg" ||
        e.target.files[0].type == "image/png"
      ) {
        that.$http
          .postFormAPI("/uploadImg", formData)
          .then((res) => {
            if (res.data.code === 200) {
              let url =
                "https://ceshi.api.yuangtech.com/" +
                res.data.data +
                "?v=" +
                Math.round(Math.random() * 5);
              that.form.pic = url;
              // console.log()
              // const options = {
              //   avatar: url
              // }
              // that.tim
              // .updateMyProfile(options)
              // .then(() => {
              //   that.$store.commit('showMessage', {
              //     message: '修改成功'
              //   })
              // })
              // .catch(imError => {
              //   that.$store.commit('showMessage', {
              //     message: imError.message,
              //     type: 'error'
              //   })
              // })
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.error("上传失败,只能上传png或jpg图片类型");
      }
    },
    onSubmit() {
      const that = this;
      const data = {
        name: that.form.name,
        work_unit: that.form.work_unit,
        nickname: that.form.nickname,
        pic: that.form.pic,
        member_id: localStorage.getItem("member_id"),
      };
      that.$http
        .postApi("/edit_member", data)
        .then((res) => {
          if (res.data.code === 200) {
            const options = {
              avatar: that.form.pic,
            };
            that.tim
              .updateMyProfile(options)
              .then(() => {
                // that.$store.commit('showMessage', {
                //   message: '修改成功'
                // })
              })
              .catch((imError) => {
                that.$store.commit("showMessage", {
                  message: imError.message,
                  type: "error",
                });
              });
            document.getElementsByClassName("saves")[0].style.opacity = 0.5;
            setTimeout(() => {
              document.getElementsByClassName("saves")[0].style.opacity = 0;
            }, 2000);
            that.getinfo();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendcode() {
      const that = this;
      let text =
        /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[0-9]|18[0-9]|14[57])[0-9]{8}$/;
      if (text.test(that.form.phone)) {
        const data = {
          phone: that.form.phone,
        };
        if (this.code === "发送验证码" || this.code === "重新发送") {
          that.$http
            .postApi("/sendCode", data)
            .then((res) => {
              if (res.data.code === 200) {
                that.$message.success("发送成功！");
                let code = 60;
                let timer = setInterval(() => {
                  if (code > 0) {
                    code--;
                    this.code = "倒计时" + code;
                  } else {
                    this.code = "重新发送";
                    clearInterval(timer);
                  }
                }, 1000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        that.$message.error("请输入正确的手机号！");
      }
    },
    cancellation() {
      const that = this;
      const data = {
        member_id: localStorage.getItem("member_id"),
        cancellation: 1,
      };
      that.$http
        .postApi("/edit_member", data)
        .then((res) => {
          if (res.data.code === 200) {
            that.$message.success("注销成功！");
            localStorage.clear();
            that.$router.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getinfo() {
      const that = this;
      const data = {
        member_id: localStorage.getItem("member_id"),
      };
      that.$http
        .postApi("/member_info", data)
        .then((res) => {
          if (res.data.code === 200) {
            this.form.name = res.data.data.name;
            this.form.nickname = res.data.data.nickname;
            this.form.phone = res.data.data.phone;
            this.form.work_unit = res.data.data.work_unit;
            this.form.pic = res.data.data.pic;
            that.$store.commit("saveavatar", res.data.data.pic);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    myprofile() {
      return JSON.parse(localStorage.getItem("sid"));
    },
  },
  mounted() {
    this.getinfo();
  },
};
</script>

<style lang="scss" scoped>
.myProfile {
  background-color: #fff;
  padding: 62px 62px 32px 62px;
  margin-bottom: 49px;
  position: relative;
  .el-form {
    width: 70%;
  }
  .myavator {
    width: 115px;
    height: 115px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      width: 100%;
      height: 36px;
      background: rgba($color: #000000, $alpha: 0.5);
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      color: #ffffff;
      line-height: 36px;
      position: absolute;
      bottom: 0;
      cursor: pointer;
    }
  }
  .code {
    position: absolute;
    right: 16px;
  }
  .codes {
    position: absolute;
    right: -45px;
  }
  .el-button--primary.is-plain.codes {
    width: 52px;
    height: 32px;
    padding: 0;
    background: rgba(61, 126, 255, 0.2);
    top: 4px;
    right: -60px;
    color: #3d7eff;
    border: none;
  }
  .danger {
    display: flex;
    .el-button {
      margin-left: auto;
      width: 80px;
      height: 32px;
      // line-height: 32px;
      padding: 0;
      border-radius: 4px;
      border: none;
    }
  }
  .submit {
    width: 144px;
    height: 40px;
    border-radius: 2px;
    float: right;
  }
  .saves {
    width: 179px;
    height: 54px;
    // opacity: 0.5;
    background: #101b28;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    line-height: 54px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 110px;
    // display: none;
    opacity: 0;
    transition: opacity 2s ease;
  }
}
</style>