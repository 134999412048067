<template>
  <div v-html="detail">
    <!-- <img src="../../../assets/img/Rectangle 21@2x.png" alt=""> -->
  </div>
</template>

<script>
export default {
  props: ['detail']
}
</script>

<style>

</style>