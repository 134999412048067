<template>
  <div class="content">
    <div class="flexbox">
      <div
        class="Selection"
        :class="{ bgImg: index === 1 }"
        v-for="(l, index) in list"
        :key="l.infoid"
      >
        <h3>
          <img src="../../assets/img/定制 (1)@2x.png" alt="" />{{ l.title }}
        </h3>
        <p>{{ l.contents }}</p>
        <el-button type="primary" @click="toPage('/design', l.infoid)"
          >立即定制</el-button
        >
      </div>
    </div>
    <div class="advantage">
      <h2>定制方案优势</h2>
      <ul>
        <li>
          <img src="../../assets/img/我的认证@2x.png" alt="" />
          <h3>特殊展位定制设计</h3>
          <p>
            外广告设计在中国的宣传范围极广，凡是在露天或公共场合都可以见到户外广告，其主要宣传的目的是满足消费者的需求，推销企业的商品等。
          </p>
        </li>
        <li>
          <img src="../../assets/img/产品百科@2x.png" alt="" />
          <h3>广告标识设计</h3>
          <p>
            创意=创异+创议+创艺+创益+创忆设计贵在创新，但创新并不是一味的求新、求异，而是要有创新的方法。
          </p>
        </li>
        <li>
          <img src="../../assets/img/待打卡@2x.png" alt="" />
          <h3>特殊展位定制设计</h3>
          <p>
            外广告设计在中国的宣传范围极广，凡是在露天或公共场合都可以见到户外广告，其主要宣传的目的是满足消费者的需求，推销企业的商品等。
          </p>
        </li>
        <li>
          <img src="../../assets/img/platform-color@2x.png" alt="" />
          <h3>特殊展位定制设计</h3>
          <p>
            外广告设计在中国的宣传范围极广，凡是在露天或公共场合都可以见到户外广告，其主要宣传的目的是满足消费者的需求，推销企业的商品等。
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: []
    }
  },
  methods: {
    toPage (page, type) {
      // this.$router.push(page)
      if (localStorage.getItem('member_id')) {
        let routeData = this.$router.resolve({ path: page, query: { id: type } });
        window.open(routeData.href, '_blank');
      } else {
        let routeData = this.$router.resolve({ path: '/login' });
        window.open(routeData.href, '_blank');
      }
    },
    getData () {
      const that = this
      const data = {
        limit: 10,
        page: 1
      }
      that.$http.postApi('/pcNeedTemplateList', data).then(res => {
        if (res.data.code == 200) {
          that.list = res.data.data.list
          if (that.list.length === 1) {
            this.$router.push({ path: '/design', query: { id: that.list[0].infoid } });
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted () {
    this.getData()
  },
}
</script>

<style lang="scss" scoped>
.flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Selection {
  margin-top: 52px;
  // max-width: 308px;
  width: 308px;
  padding: 33px 35px;
  background-image: url('../../assets/img/Frame 771@2x.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f5f5f6;
  h3 {
    font-size: 17px;
    font-weight: 500;
    color: #101b28;
    text-shadow: 0px 4px 15px 2px rgba(195, 203, 205, 0.1);
    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      vertical-align: middle;
    }
  }
  p {
    font-size: 13px;
    font-weight: 500;
    color: #525b66;
    text-shadow: 0px 4px 15px 2px rgba(195, 203, 205, 0.1);
    word-break: break-all;
    margin: 12px 0;
    max-width: 308px;
  }
  .el-button {
    width: 144px;
    height: 40px;
    border-radius: 2px;
    box-shadow: 0px 4px 15px 2px rgba(195, 203, 205, 0.1),
      0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  }
}
.bgImg {
  background-image: url('../../assets/img/Frame 771_1@2x.png');
}
.advantage {
  margin-top: 56px;
  margin-bottom: 20%;
  h2 {
    font-size: 24px;
    text-align: center;
    color: #101b28;
    margin-bottom: 32px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    li {
      img {
        width: 52px;
        height: 52px;
      }
      h3 {
        font-size: 17px;
        font-weight: 500;
        color: #101b28;
        margin: 16px 0;
      }
      p {
        max-width: 258px;
        font-size: 13px;
        font-weight: 500;
        color: #525b66;
      }
    }
  }
}
</style>